import React from "react";

export const SvgArrowRight = ({ currentSlide, slideCount, ...props }) => {
	return (
		<svg
			width="54"
			height="54"
			viewBox="0 0 54 54"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
			aria-hidden="true"
			aria-disabled={currentSlide === 0 ? true : false}
			type="button"
		>
			<g filter="url(#filter0_d_1831_3951)">
				<rect x="15" y="11" width="24" height="24" rx="12" fill="white" />
			</g>
			<g clipPath="url(#clip0_1831_3951)">
				<path
					d="M29.419 23.0019C29.419 23.1541 29.3609 23.3063 29.2449 23.4223L25.5933 27.0738C25.3611 27.3061 24.9845 27.3061 24.7523 27.0738C24.5201 26.8416 24.5201 26.4651 24.7523 26.2328L27.9834 23.0019L24.7524 19.771C24.5202 19.5387 24.5202 19.1622 24.7524 18.9301C24.9846 18.6977 25.3612 18.6977 25.5935 18.9301L29.245 22.5815C29.361 22.6976 29.419 22.8498 29.419 23.0019Z"
					fill="#333333"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_1831_3951"
					x="0"
					y="0"
					width="54"
					height="54"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="4" />
					<feGaussianBlur stdDeviation="7.5" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.4 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_1831_3951"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_1831_3951"
						result="shape"
					/>
				</filter>
				<clipPath id="clip0_1831_3951">
					<rect
						width="8.49231"
						height="8.49231"
						fill="white"
						transform="translate(22.7524 27.248) rotate(-90)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
