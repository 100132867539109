import { customDates } from "multi-date";

const restrictDates = [
	"2024-06-30",
	"2024-12-24",
	"2024-12-25",
	"2024-12-30",
	"2024-12-31",
	"2025-01-01",
	"2025-03-14",
	"2025-03-15",
];
const restrictDatesGoa = [
	"2024-06-30",
	"2024-12-14",
	"2024-12-15",
	"2024-12-16",
	"2024-12-17",
	"2024-12-18",
	"2024-12-19",
	"2024-12-20",
	"2024-12-21",
	"2024-12-22",
	"2024-12-23",
	"2024-12-24",
	"2024-12-25",
	"2024-12-26",
	"2024-12-27",
	"2024-12-28",
	"2024-12-29",
	"2024-12-30",
	"2024-12-31",
	"2025-01-01",
	"2025-01-02",
	"2025-01-03",
	"2025-01-04",
	"2025-01-05",
	"2025-03-14",
	"2025-03-15",
];

const GoaIds = [
	"1FZWk66fCBCN7mJa",
	"76vPjFXotKB21SZk",
	"8UV7fq6DBUgAjYfS",
	"bOeqYGR14FMMS4zE",
	"BQZ6ZEIO6UNbWXyZ",
	"Dx1FqYbzOWptyOGz",
	"ldHlFEx6lGaotjt2",
	"mLX860Fn9RxSQQWL",
	"nV3g7rO78vy5MaRn",
	"o2EGR6cIA1UH9cFX",
	"ousJZq8vMRbvHBTf",
	"p5RfsG6ie1uxecQ6",
	"shgM3qyY7ZV6JvmM",
	"SsqrusersucGRHvf",
	"STET7IKX6BNzFMhg",
	"sZtgQtVa6CEeQdX2",
	"Uqdj8lF1BN6M8eKc",
	"vXkVEmP1cIKqxYzN",
	"VYhB37ZS1WxeHyon",
	"WD1ojL072QBXeZxj",
	"xk2qe7Rkr5duExn1",
	"Yo5xMN6WA0bcyBrU",
	"zPicNIkypnwqPGCg",
];

var Ids = [
	"8UV7fq6DBUgAjYfS",
	"BQZ6ZEIO6UNbWXyZ",
	"ldHlFEx6lGaotjt2",
	"mLX860Fn9RxSQQWL",
	"p5RfsG6ie1uxecQ6",
	"STET7IKX6BNzFMhg",
	"sZtgQtVa6CEeQdX2",
	"Uqdj8lF1BN6M8eKc",
	"vXkVEmP1cIKqxYzN",
	"xk2qe7Rkr5duExn1",
];

export const minNightsData = (villa_id = "") => {
	const startDate = new Date();
	const endDate = customDates(new Date(), 6, "month");

	if (Ids.includes(villa_id)) {
		return [...restrictDates];
	}
	if (GoaIds.includes(villa_id)) {
		return [...restrictDatesGoa];
	}
	if (villa_id === "I14eRBfO2Cz5tjPg") {
		return [];
	}
	if (villa_id === "sZtgQtVa6CEeQdX2") {
		return [...restrictDates, ...OctToJan()];
	}
	if (villa_id === "c5OinTRYX2e0nUWZ") {
		return [...restrictDates, ...getDatesInRange(startDate, endDate)];
	}
	return [...restrictDates];
};

export default minNightsData;

function getDatesInRange(startDate, endDate) {
	const dates = [];
	const currentDate = new Date(startDate);

	while (currentDate <= endDate) {
		const year = currentDate.getFullYear();
		const month = String(currentDate.getMonth() + 1).padStart(2, "0");
		const day = String(currentDate.getDate()).padStart(2, "0");
		const formattedDate = `${year}-${month}-${day}`;
		dates.push(formattedDate);

		currentDate.setDate(currentDate.getDate() + 1);
	}

	return dates;
}

function OctToJan() {
	const startDate = new Date("2023-10-01");
	const endDate = new Date("2024-01-31");
	const dateList = [];

	const currentDate = new Date(startDate);

	while (currentDate <= endDate) {
		const formattedDate = currentDate.toISOString().split("T")[0];
		dateList.push(formattedDate);
		currentDate.setDate(currentDate.getDate() + 1);
	}

	return dateList;
}

const getWeekendDates = () => {
	const startDate = new Date("2023-11-01"); // Start date for November 2023
	const endDate = new Date("2024-01-31"); // End date for January 2024
	const dates = [];

	// Loop through each date within the range
	while (startDate <= endDate) {
		// Check if the current date is a Saturday or Sunday
		if (startDate.getDay() === 0 || startDate.getDay() === 6) {
			const year = startDate.getFullYear();
			const month = String(startDate.getMonth() + 1).padStart(2, "0");
			const day = String(startDate.getDate()).padStart(2, "0");
			const formattedDate = `${year}-${month}-${day}`;
			dates.push(formattedDate);
		}

		// Move to the next day
		startDate.setDate(startDate.getDate() + 1);
	}

	return dates;
};
