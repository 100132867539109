import React from "react";

export const SvgFilledHeart = (props: any) => {
	return (
		<svg
			width="16"
			height="14"
			viewBox="0 0 16 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M14.8661 1.24976C14.1101 0.511535 13.0655 0.142578 11.7321 0.142578C11.363 0.142578 10.9864 0.206562 10.6026 0.334468C10.2186 0.462468 9.86135 0.635157 9.53129 0.852409C9.2008 1.0696 8.91678 1.27356 8.67851 1.46398C8.44053 1.65446 8.21427 1.85689 8.00002 2.07111C7.78567 1.85689 7.55947 1.65446 7.32146 1.46398C7.08328 1.27356 6.79914 1.06972 6.46871 0.852409C6.13834 0.635063 5.78117 0.462499 5.39727 0.334468C5.01339 0.206594 4.63683 0.142578 4.26781 0.142578C2.93443 0.142578 1.88985 0.511629 1.13392 1.24976C0.377996 1.98783 0 3.01158 0 4.3211C0 4.71992 0.0700513 5.13072 0.209872 5.55319C0.349694 5.97581 0.509029 6.33598 0.687566 6.63354C0.866072 6.93107 1.0685 7.22134 1.2947 7.50408C1.5209 7.78685 1.6862 7.98162 1.79025 8.08885C1.89439 8.19587 1.97626 8.27333 2.03577 8.32087L7.60713 13.6959C7.71431 13.8032 7.84525 13.8568 7.99998 13.8568C8.15472 13.8568 8.28576 13.8032 8.39287 13.6961L13.9554 8.33888C15.3185 6.97591 16 5.63659 16 4.3211C16 3.01158 15.622 1.98783 14.8661 1.24976Z"
				fill="url(#paint0_linear_1831_3948)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_1831_3948"
					x1="0.7"
					y1="0.485433"
					x2="13.1008"
					y2="15.3468"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF3F5D" />
					<stop offset="1" stopColor="#FF8267" />
				</linearGradient>
			</defs>
		</svg>
	);
};
