export const SvgPetFriendly = (props: any) => {
	return (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M18.7744 6.97676C18.6287 6.91942 18.4733 6.8902 18.3167 6.89063H18.3003C17.473 6.90293 16.5452 7.67772 16.0584 8.84954C15.4747 10.2514 15.7434 11.6858 16.6629 12.0545C16.8086 12.1118 16.9638 12.1411 17.1203 12.1406C17.9517 12.1406 18.8921 11.3613 19.3831 10.1817C19.9627 8.77981 19.6899 7.34549 18.7744 6.97676Z"
				stroke="#666666"
				strokeWidth="1.5"
				strokeMiterlimit="10"
			/>
			<path
				d="M13.4367 12.4474C12.2965 10.5554 11.8043 9.84375 10.5 9.84375C9.19568 9.84375 8.69939 10.5599 7.55916 12.4474C6.58299 14.0618 4.61014 14.1963 4.11795 15.5675C4.0181 15.8186 3.96795 16.0868 3.97029 16.357C3.97029 17.4722 4.82342 18.375 5.87342 18.375C7.17771 18.375 8.95369 17.3336 10.5041 17.3336C12.0545 17.3336 13.8222 18.375 15.1265 18.375C16.1765 18.375 17.0256 17.4727 17.0256 16.357C17.0265 16.0865 16.975 15.8184 16.8738 15.5675C16.3816 14.1914 14.4129 14.0618 13.4367 12.4474Z"
				stroke="#666666"
				strokeWidth="1.5"
				strokeMiterlimit="10"
			/>
			<path
				d="M7.8961 8.03906C7.95102 8.03911 8.00587 8.03499 8.06016 8.02676C9.01214 7.88854 9.60645 6.56947 9.39071 5.07979C9.18768 3.67541 8.33538 2.625 7.44411 2.625C7.38919 2.62496 7.33435 2.62907 7.28005 2.63731C6.32807 2.77553 5.73376 4.09459 5.9495 5.58428C6.15253 6.98455 7.00483 8.03906 7.8961 8.03906Z"
				stroke="#666666"
				strokeWidth="1.5"
				strokeMiterlimit="10"
			/>
			<path
				d="M15.0496 5.58428C15.2654 4.09459 14.6711 2.77553 13.7191 2.63731C13.6648 2.62907 13.6099 2.62496 13.555 2.625C12.6638 2.625 11.8131 3.67541 11.6097 5.07979C11.3939 6.56947 11.9882 7.88854 12.9402 8.02676C12.9945 8.03499 13.0493 8.03911 13.1043 8.03906C13.9955 8.03906 14.8478 6.98455 15.0496 5.58428Z"
				stroke="#666666"
				strokeWidth="1.5"
				strokeMiterlimit="10"
			/>
			<path
				d="M4.33815 12.0545C5.25649 11.6854 5.52473 10.2498 4.9419 8.84954C4.45135 7.66993 3.51168 6.89063 2.68153 6.89063C2.52501 6.8902 2.36984 6.91943 2.2242 6.97676C1.30586 7.3459 1.03762 8.78145 1.62045 10.1817C2.111 11.3613 3.05067 12.1406 3.88082 12.1406C4.03734 12.1411 4.19251 12.1118 4.33815 12.0545V12.0545Z"
				stroke="#666666"
				strokeWidth="1.5"
				strokeMiterlimit="10"
			/>
		</svg>
	);
};
