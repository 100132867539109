export const SvgListingSignature = () => {
	return (
		<svg
			width="90"
			height="20"
			viewBox="0 0 90 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="89.1927" height="19.0811" rx="2" fill="black" />
			<rect
				width="89.1927"
				height="19.0811"
				rx="2"
				fill="white"
				fill-opacity="0.05"
			/>
			<path
				d="M27.0284 12.4381C26.6558 12.4381 26.3265 12.3732 26.0404 12.2434C25.7596 12.1136 25.5383 11.9324 25.3763 11.6997C25.2144 11.4671 25.128 11.2075 25.1172 10.9208H25.7165C25.7434 11.1967 25.8649 11.4401 26.0809 11.6511C26.2968 11.8566 26.6127 11.9594 27.0284 11.9594C27.4117 11.9594 27.714 11.862 27.9354 11.6673C28.1621 11.4671 28.2755 11.2156 28.2755 10.9127C28.2755 10.6692 28.2134 10.4745 28.0892 10.3284C27.9651 10.177 27.8112 10.0661 27.6276 9.99574C27.4441 9.92001 27.1903 9.83886 26.8664 9.75231C26.4885 9.64953 26.1888 9.54945 25.9675 9.45208C25.7461 9.35471 25.5572 9.20324 25.4006 8.99768C25.2441 8.79212 25.1658 8.51353 25.1658 8.16191C25.1658 7.86979 25.2414 7.61013 25.3925 7.38293C25.5437 7.15032 25.7569 6.9691 26.0323 6.83927C26.3076 6.70945 26.6235 6.64453 26.9798 6.64453C27.5035 6.64453 27.9246 6.77436 28.2431 7.03402C28.567 7.28827 28.7533 7.61825 28.8019 8.02396H28.1864C28.1486 7.79135 28.0217 7.58579 27.8058 7.40728C27.5898 7.22335 27.2983 7.13139 26.9312 7.13139C26.5911 7.13139 26.3076 7.22335 26.0809 7.40728C25.8541 7.58579 25.7407 7.83193 25.7407 8.14568C25.7407 8.3837 25.8028 8.57574 25.927 8.72179C26.0512 8.86785 26.205 8.97875 26.3886 9.05448C26.5776 9.13021 26.8313 9.21136 27.1498 9.29791C27.517 9.40069 27.8139 9.50347 28.0406 9.60625C28.2674 9.70363 28.459 9.85509 28.6156 10.0607C28.7722 10.2662 28.8505 10.5421 28.8505 10.8883C28.8505 11.1534 28.7803 11.4049 28.6399 11.6429C28.4995 11.881 28.2917 12.073 28.0163 12.2191C27.741 12.3651 27.4117 12.4381 27.0284 12.4381Z"
				fill="url(#paint0_linear_16889_23547)"
			/>
			<path
				d="M32.1324 6.70945V12.3813H31.5655V6.70945H32.1324Z"
				fill="url(#paint1_linear_16889_23547)"
			/>
			<path
				d="M39.4897 8.36476C39.3278 7.99151 39.0794 7.69939 38.7447 7.48842C38.4154 7.27204 38.0266 7.16385 37.5785 7.16385C37.1574 7.16385 36.7795 7.26122 36.4448 7.45596C36.1101 7.65071 35.8455 7.9293 35.6512 8.29174C35.4568 8.64877 35.3596 9.0653 35.3596 9.54134C35.3596 10.0174 35.4568 10.4366 35.6512 10.7991C35.8455 11.1615 36.1101 11.4401 36.4448 11.6348C36.7795 11.8296 37.1574 11.9269 37.5785 11.9269C37.9727 11.9269 38.3263 11.8431 38.6394 11.6754C38.9579 11.5023 39.2117 11.2589 39.4006 10.9451C39.595 10.626 39.7057 10.2554 39.7327 9.83345H37.3518V9.37094H40.3319V9.78477C40.3049 10.2824 40.17 10.7341 39.927 11.1399C39.6841 11.5402 39.3574 11.8566 38.9471 12.0892C38.5422 12.3218 38.086 12.4381 37.5785 12.4381C37.0549 12.4381 36.5798 12.3164 36.1533 12.073C35.7268 11.8242 35.3893 11.4807 35.141 11.0425C34.898 10.5989 34.7766 10.0985 34.7766 9.54134C34.7766 8.98416 34.898 8.48648 35.141 8.04831C35.3893 7.60472 35.7268 7.26122 36.1533 7.01779C36.5798 6.76895 37.0549 6.64453 37.5785 6.64453C38.1832 6.64453 38.7123 6.796 39.1658 7.09893C39.6193 7.40187 39.9486 7.82381 40.1538 8.36476H39.4897Z"
				fill="url(#paint2_linear_16889_23547)"
			/>
			<path
				d="M47.2577 12.3813H46.6909L43.5569 7.61013V12.3813H42.99V6.70945H43.5569L46.6909 11.4725V6.70945H47.2577V12.3813Z"
				fill="url(#paint3_linear_16889_23547)"
			/>
			<path
				d="M53.4411 11.0344H50.8659L50.3719 12.3813H49.7726L51.8376 6.78247H52.4774L54.5343 12.3813H53.9351L53.4411 11.0344ZM53.271 10.5637L52.1535 7.49653L51.0359 10.5637H53.271Z"
				fill="url(#paint4_linear_16889_23547)"
			/>
			<path
				d="M60.3759 6.70945V7.18008H58.8049V12.3813H58.238V7.18008H56.6589V6.70945H60.3759Z"
				fill="url(#paint5_linear_16889_23547)"
			/>
			<path
				d="M63.4332 6.70945V10.2797C63.4332 10.8423 63.5655 11.2589 63.8301 11.5293C64.1 11.7998 64.4671 11.9351 64.9314 11.9351C65.3957 11.9351 65.7574 11.8025 66.0166 11.5375C66.2811 11.267 66.4134 10.8477 66.4134 10.2797V6.70945H66.9803V10.2716C66.9803 10.9911 66.7886 11.532 66.4053 11.8945C66.0274 12.2569 65.5334 12.4381 64.9233 12.4381C64.3133 12.4381 63.8166 12.2569 63.4332 11.8945C63.0553 11.532 62.8664 10.9911 62.8664 10.2716V6.70945H63.4332Z"
				fill="url(#paint6_linear_16889_23547)"
			/>
			<path
				d="M72.8768 12.3813L71.4758 10.012H70.4312V12.3813H69.8643V6.70945H71.5649C72.1966 6.70945 72.6744 6.86091 72.9983 7.16385C73.3276 7.46678 73.4923 7.86438 73.4923 8.35665C73.4923 8.76778 73.3735 9.1194 73.136 9.41151C72.9038 9.69822 72.5583 9.88485 72.0994 9.9714L73.5571 12.3813H72.8768ZM70.4312 9.54945H71.573C72.0157 9.54945 72.3477 9.44126 72.5691 9.22488C72.7958 9.0085 72.9092 8.71909 72.9092 8.35665C72.9092 7.98339 72.8012 7.69669 72.5853 7.49653C72.3693 7.29097 72.0292 7.18819 71.5649 7.18819H70.4312V9.54945Z"
				fill="url(#paint7_linear_16889_23547)"
			/>
			<path
				d="M76.8038 7.17196V9.2898H78.9498V9.76043H76.8038V11.9107H79.1927V12.3813H76.2369V6.70133H79.1927V7.17196H76.8038Z"
				fill="url(#paint8_linear_16889_23547)"
			/>
			<path
				d="M20.3532 6.80813C20.1894 6.75846 20.0029 6.83698 19.922 6.98933C19.5005 7.79431 18.8276 8.75958 18.0479 8.7211C16.968 8.68419 15.9516 6.90274 15.65 6.21968C15.6435 6.20368 15.6321 6.19406 15.6241 6.18276C15.6079 6.15715 15.5916 6.12986 15.569 6.10738C15.5528 6.09138 15.5334 6.08009 15.5139 6.0689C15.4944 6.05771 15.4798 6.03848 15.4587 6.02886H15.4506C15.4279 6.01924 15.402 6.01599 15.3761 6.01286C15.3533 6.00794 15.3339 6 15.3112 6C15.2884 6 15.269 6.00794 15.2479 6.01275C15.2204 6.01599 15.1945 6.01913 15.1717 6.02875H15.162C15.1426 6.03836 15.1295 6.05436 15.1118 6.06398C15.0923 6.07852 15.0696 6.08814 15.0517 6.10738C15.029 6.12986 15.0144 6.15547 14.9982 6.18276C14.9901 6.19395 14.9788 6.20357 14.9724 6.21968C14.6692 6.90274 13.6543 8.68419 12.5761 8.7211C11.8012 8.76595 11.1235 7.79431 10.7003 6.98933C10.6192 6.83542 10.4345 6.75679 10.2658 6.80813C10.0972 6.85779 9.98538 7.01975 10.0016 7.19299L10.5285 12.7491C10.5464 12.9384 10.7053 13.0811 10.8982 13.0811L15.3112 13.081H19.7258C19.9155 13.081 20.0776 12.9383 20.0938 12.749L20.6207 7.19288C20.6353 7.01974 20.5251 6.85778 20.3532 6.8081L20.3532 6.80813ZM19.3901 12.3514H11.2353L10.8787 8.57827C11.4153 9.18116 11.994 9.4827 12.602 9.45541C13.8585 9.41045 14.8232 8.04431 15.3111 7.18002C15.7991 8.04431 16.7638 9.41046 18.0202 9.45372C18.6362 9.48101 19.2069 9.17958 19.7435 8.57658L19.3901 12.3514Z"
				fill="url(#paint9_linear_16889_23547)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_16889_23547"
					x1="25.5669"
					y1="6.88859"
					x2="25.5527"
					y2="13.061"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#FFF500" />
					<stop offset="0.161458" stop-color="#BD9A64" />
					<stop offset="0.472222" stop-color="#FBDE98" />
					<stop offset="0.802083" stop-color="#BD9A64" />
					<stop offset="1" stop-color="#857D4C" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_16889_23547"
					x1="25.5669"
					y1="6.88859"
					x2="25.5527"
					y2="13.061"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#FFF500" />
					<stop offset="0.161458" stop-color="#BD9A64" />
					<stop offset="0.472222" stop-color="#FBDE98" />
					<stop offset="0.802083" stop-color="#BD9A64" />
					<stop offset="1" stop-color="#857D4C" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_16889_23547"
					x1="25.5669"
					y1="6.88859"
					x2="25.5527"
					y2="13.061"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#FFF500" />
					<stop offset="0.161458" stop-color="#BD9A64" />
					<stop offset="0.472222" stop-color="#FBDE98" />
					<stop offset="0.802083" stop-color="#BD9A64" />
					<stop offset="1" stop-color="#857D4C" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_16889_23547"
					x1="25.5669"
					y1="6.88859"
					x2="25.5527"
					y2="13.061"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#FFF500" />
					<stop offset="0.161458" stop-color="#BD9A64" />
					<stop offset="0.472222" stop-color="#FBDE98" />
					<stop offset="0.802083" stop-color="#BD9A64" />
					<stop offset="1" stop-color="#857D4C" />
				</linearGradient>
				<linearGradient
					id="paint4_linear_16889_23547"
					x1="25.5669"
					y1="6.88859"
					x2="25.5527"
					y2="13.061"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#FFF500" />
					<stop offset="0.161458" stop-color="#BD9A64" />
					<stop offset="0.472222" stop-color="#FBDE98" />
					<stop offset="0.802083" stop-color="#BD9A64" />
					<stop offset="1" stop-color="#857D4C" />
				</linearGradient>
				<linearGradient
					id="paint5_linear_16889_23547"
					x1="25.5669"
					y1="6.88859"
					x2="25.5527"
					y2="13.061"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#FFF500" />
					<stop offset="0.161458" stop-color="#BD9A64" />
					<stop offset="0.472222" stop-color="#FBDE98" />
					<stop offset="0.802083" stop-color="#BD9A64" />
					<stop offset="1" stop-color="#857D4C" />
				</linearGradient>
				<linearGradient
					id="paint6_linear_16889_23547"
					x1="25.5669"
					y1="6.88859"
					x2="25.5527"
					y2="13.061"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#FFF500" />
					<stop offset="0.161458" stop-color="#BD9A64" />
					<stop offset="0.472222" stop-color="#FBDE98" />
					<stop offset="0.802083" stop-color="#BD9A64" />
					<stop offset="1" stop-color="#857D4C" />
				</linearGradient>
				<linearGradient
					id="paint7_linear_16889_23547"
					x1="25.5669"
					y1="6.88859"
					x2="25.5527"
					y2="13.061"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#FFF500" />
					<stop offset="0.161458" stop-color="#BD9A64" />
					<stop offset="0.472222" stop-color="#FBDE98" />
					<stop offset="0.802083" stop-color="#BD9A64" />
					<stop offset="1" stop-color="#857D4C" />
				</linearGradient>
				<linearGradient
					id="paint8_linear_16889_23547"
					x1="25.5669"
					y1="6.88859"
					x2="25.5527"
					y2="13.061"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#FFF500" />
					<stop offset="0.161458" stop-color="#BD9A64" />
					<stop offset="0.472222" stop-color="#FBDE98" />
					<stop offset="0.802083" stop-color="#BD9A64" />
					<stop offset="1" stop-color="#857D4C" />
				</linearGradient>
				<linearGradient
					id="paint9_linear_16889_23547"
					x1="19.8198"
					y1="7.68857"
					x2="11.1004"
					y2="13.2027"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#857D4C" />
					<stop offset="0.254413" stop-color="#BD9A64" />
					<stop offset="0.497128" stop-color="#FBDE98" />
					<stop offset="0.743825" stop-color="#BD9A64" />
					<stop offset="1" stop-color="#857D4C" />
				</linearGradient>
			</defs>
		</svg>
	);
};
