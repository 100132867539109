export const SvgPool = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.8125 15.9375L4.51875 15.3469C7.07812 14.4938 9.96563 15.0844 12 16.9219C14.0344 18.7594 16.8562 19.35 19.4812 18.4969L21.1875 17.9063"
				stroke="#666666"
				strokeWidth="1.7"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.5938 12C18.0435 12 19.2188 10.8247 19.2188 9.375C19.2188 7.92525 18.0435 6.75 16.5938 6.75C15.144 6.75 13.9688 7.92525 13.9688 9.375C13.9688 10.8247 15.144 12 16.5938 12Z"
				stroke="#666666"
				strokeWidth="1.7"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.3127 17.9063L13.9689 17.25C15.0846 16.1344 15.0846 14.3625 13.9689 13.3125L9.04707 8.39066C8.12832 7.47191 6.68457 7.47191 5.76582 8.39066L2.87832 11.2782C2.48457 11.6719 2.48457 12.3282 2.87832 12.7219C3.20645 13.05 3.73145 13.1157 4.19082 12.8532L7.40645 10.6875L9.3752 12.6563L7.07832 14.9532"
				stroke="#666666"
				strokeWidth="1.7"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
