// style
import "./style.scss";

export const FeatureCardsSkeleton = () => {
	const cardTop = <div className="skeleton__top-card"></div>;

	const cardMiddle = (
		<div className="skeleton__middle-card">
			<div className="skeleton__card-title"></div>
			<div className="skeleton__card-location"></div>
			<div className="skeleton__card-type"></div>
			<div className="skeleton__card-benefits"></div>
			<div className="skeleton__card-reviews"></div>
		</div>
	);

	const cardBottom = (
		<div className="skeleton__bottom-card">
			<div className="featured__card-button">
				<div></div>
				<div className="link-button"></div>
			</div>
		</div>
	);

	return (
		<div className="skeleton-card">
			{cardTop}
			{cardMiddle}
			{cardBottom}
		</div>
	);
};
