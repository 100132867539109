export const SvgVeg = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0.5"
				y="0.5"
				width="23"
				height="23"
				rx="1.5"
				fill="white"
				stroke="#11BF0E"
			/>
			<rect x="5" y="5" width="14" height="14" rx="7" fill="#11BF0E" />
		</svg>
	);
};
