import React from "react";

export const SvgButtonArrowLeft = (props) => {
	return (
		<svg
			width="34"
			height="34"
			viewBox="0 0 34 34"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g opacity="0.5">
				<rect
					x="-0.5"
					y="0.5"
					width="33"
					height="33"
					rx="16.5"
					transform="matrix(-1 0 0 1 33 0)"
					fill={props?.rectFill || "white"}
					stroke={props?.rectStroke || "#999999"}
				/>
				<g clipPath="url(#clip0_391_3385)">
					<path
						d="M13.5708 17.0003C13.5708 17.2159 13.6532 17.4315 13.8175 17.5959L18.9905 22.7688C19.3195 23.0979 19.8531 23.0979 20.182 22.7688C20.5109 22.4399 20.5109 21.9065 20.182 21.5774L15.6046 17.0003L20.1819 12.4231C20.5108 12.0941 20.5108 11.5607 20.1819 11.2318C19.8529 10.9026 19.3194 10.9026 18.9903 11.2318L13.8173 16.4047C13.653 16.5691 13.5708 16.7847 13.5708 17.0003Z"
						fill={props?.pathFill || "#333333"}
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_391_3385">
					<rect
						width="12.0308"
						height="12.0308"
						fill={props?.rectFill || "white"}
						transform="matrix(0 -1 -1 0 23.0151 23.0156)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
