import { createContext, useState } from "react";
import { useQuery } from "react-query";
import { goAxiosInstance } from "helpers/axiosInstance";

export const FeaturedHomesInterface = createContext([]);

export const FeaturedHomesData = ({ children }: any) => {
	const [query, setQuery] = useState("Maharashtra");
	const [location, setLocation] = useState({
		latitude: "18.9809",
		longitude: "73.5008",
	});

	const featuredHomes = useQuery(
		["featured homes", query, location.latitude, location.longitude],
		() =>
			goAxiosInstance({
				method: "GET",
				url: `/featured?lat=${location.latitude}&lon=${location.longitude}`,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			}),
	);

	const data: any = {
		featuredHomes,
		setQuery,
		setLocation,
	};

	return (
		<FeaturedHomesInterface.Provider value={data}>
			{children}
		</FeaturedHomesInterface.Provider>
	);
};
