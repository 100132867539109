import { memo } from "react";

// commponents
import { FeatureCardsSkeleton } from "components/Loader/HomeSkeletonLoader/FeatureCardsSkeleton";
import { FeaturedCards } from "../FeaturedCards";

// helpers
import getImg from "helpers/getCloudinaryImage";
import parseHTML from "helpers/parseHTML";

// scss
import "./style.scss";
import { HomeNewCard } from "components/NewHomePage/HomeNewCard";

export const FeaturedCity = memo(({ newData, loading }) => {
	if (loading) {
		return (
			<>
				{[1, 2, 3]?.map((item) => (
					<FeatureCardsSkeleton key={Math.random() * 1000} />
				))}
			</>
		);
	}

	return (
		<>
			{newData?.map((item) => {
				const imgNewArray = item.image_list?.map((v) => {
					return getImg({
						id: v,
						version: "1636374075",
						quality: "62",
						width: "800",
						height: "460",
					});
				});
				const amen = Object.values(item?.amenities);
				const sui = Object.values(item?.suitables);

				const location = item.city + ", " + item.state;

				const benefits = [...amen, ...sui];

				const title = parseHTML(item?.file_refernce?.split(" - ")[0]);

				const type =
					item?.type_flag === "EV"
						? "Entire Home"
						: item?.type_flag === "MV"
						? "Separate Villas"
						: item?.type_flag === "SR"
						? "Single Room"
						: item?.type_flag === "MR"
						? "Separate Rooms"
						: item?.type_flag === "EV-MR"
						? "Entire Home"
						: "";

				if (title && imgNewArray.length > 0) {
					return (
						<HomeNewCard
							key={item.id}
							id={item.id}
							location={location}
							title={title}
							img={imgNewArray}
							rating={item?.rating}
							reviews={item?.reviews}
							benefits={benefits}
							signature={item?.signature_homes === "Signature" ? 1 : 0}
							newHome={false}
							price={item.price}
							noOfBedrooms={item?.bedrooms}
							capacity={item?.total_guest}
							type={type}
							seoURL={item.seo_url}
							prevLocation="Featured City"
						/>
					);
				} else {
					return null;
				}
			})}
		</>
	);
});
