import { useEffect, useState } from "react";

const useCheckEndsReached = ({ ref, rightBuffer = 0, leftBuffer = 0 }) => {
	const [reachedRight, setReachedRight] = useState(false);
	const [reachedLeft, setReachedLeft] = useState(true);

	const scrollCheck = (element) => {
		setReachedLeft(element.target.scrollLeft <= leftBuffer);
		if (
			Math.floor(element.target.scrollWidth - element.target.scrollLeft) <=
			element.target.offsetWidth + rightBuffer
		) {
			setReachedRight(true);
		} else {
			setReachedRight(false);
		}
	};

	useEffect(() => {
		if (!ref.current) return;
		ref.current.addEventListener("scroll", scrollCheck);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref.current]);

	return { reachedRight, reachedLeft };
};

export default useCheckEndsReached;
