import React from "react";

export const SvgButtonArrowRight = (props) => {
	return (
		<svg
			width="34"
			height="34"
			viewBox="0 0 34 34"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<rect
				x="0.5"
				y="0.5"
				width="33"
				height="33"
				rx="16.5"
				fill={props?.rectFill || "white"}
				stroke={props?.rectStroke || "#999999"}
			/>
			<g clipPath="url(#clip0_391_3380)">
				<path
					d="M20.4292 17.0003C20.4292 17.2159 20.3468 17.4315 20.1825 17.5959L15.0095 22.7688C14.6805 23.0979 14.1469 23.0979 13.818 22.7688C13.4891 22.4399 13.4891 21.9065 13.818 21.5774L18.3954 17.0003L13.8181 12.4231C13.4892 12.0941 13.4892 11.5607 13.8181 11.2318C14.1471 10.9026 14.6806 10.9026 15.0097 11.2318L20.1827 16.4047C20.347 16.5691 20.4292 16.7847 20.4292 17.0003Z"
					fill={props?.pathFill || "#333333"}
				/>
			</g>
			<defs>
				<clipPath id="clip0_391_3380">
					<rect
						width="12.0308"
						height="12.0308"
						fill={props?.rectFill || "white"}
						transform="translate(10.9849 23.0156) rotate(-90)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
