import { memo } from "react";

// components
import { FeaturedCity } from "../FeaturedCity";

// scss
import "./style.scss";

export const FeaturedCardContainer = memo(
	({ type, listRef, onScroll, featuredHomes }) => {
		const { data, isLoading } = featuredHomes;

		const newData = data?.data?.data?.slice(0, 6);

		return (
			<div className="featured__one">
				<div
					className="featured__one-container"
					ref={listRef}
					onScroll={onScroll}
				>
					<FeaturedCity type={type} newData={newData} loading={isLoading} />
				</div>
			</div>
		);
	},
);
