import React from "react";

export const SvgSignatureTag = (props) => {
	return (
		<svg
			width="188"
			height="57"
			viewBox="0 0 188 57"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M10 44L17.5 35.3397L17.5 52.6603L10 44Z" fill="#183E3F" />
			<path d="M10 44L17.5 35.3397L17.5 52.6603L10 44Z" fill="black" />
			<g filter="url(#filter0_d_1831_3844)">
				<path
					d="M10 11C10 8.79086 11.7909 7 14 7H174C176.209 7 178 8.79086 178 11V40C178 42.2091 176.209 44 174 44H10V11Z"
					fill="#183E3F"
				/>
				<path
					d="M10 11C10 8.79086 11.7909 7 14 7H174C176.209 7 178 8.79086 178 11V40C178 42.2091 176.209 44 174 44H10V11Z"
					fill="white"
					fillOpacity="0.05"
				/>
			</g>
			<path
				d="M61.4989 31.2084C60.8098 31.2084 60.2006 31.0883 59.6712 30.8481C59.1519 30.608 58.7424 30.2727 58.4428 29.8424C58.1432 29.4121 57.9834 28.9317 57.9634 28.4013H59.072C59.1219 28.9117 59.3466 29.362 59.7461 29.7523C60.1456 30.1326 60.7299 30.3227 61.4989 30.3227C62.208 30.3227 62.7673 30.1426 63.1768 29.7823C63.5963 29.4121 63.806 28.9467 63.806 28.3863C63.806 27.9359 63.6912 27.5756 63.4615 27.3054C63.2318 27.0252 62.9471 26.8201 62.6075 26.69C62.268 26.5499 61.7986 26.3997 61.1993 26.2396C60.5002 26.0495 59.9459 25.8643 59.5364 25.6842C59.1269 25.5041 58.7774 25.2239 58.4877 24.8436C58.1981 24.4633 58.0533 23.9479 58.0533 23.2974C58.0533 22.757 58.1931 22.2766 58.4727 21.8563C58.7524 21.4259 59.1469 21.0907 59.6563 20.8505C60.1656 20.6103 60.7499 20.4902 61.409 20.4902C62.3778 20.4902 63.1569 20.7304 63.7461 21.2108C64.3454 21.6811 64.6899 22.2916 64.7798 23.0422H63.6412C63.5713 22.6119 63.3366 22.2316 62.9371 21.9013C62.5376 21.5611 61.9983 21.3909 61.3192 21.3909C60.69 21.3909 60.1656 21.5611 59.7461 21.9013C59.3267 22.2316 59.1169 22.6869 59.1169 23.2674C59.1169 23.7077 59.2318 24.063 59.4615 24.3332C59.6912 24.6034 59.9759 24.8085 60.3154 24.9486C60.665 25.0887 61.1344 25.2389 61.7237 25.399C62.4028 25.5891 62.9521 25.7793 63.3716 25.9694C63.7911 26.1496 64.1456 26.4298 64.4352 26.8101C64.7249 27.1904 64.8697 27.7007 64.8697 28.3412C64.8697 28.8316 64.7399 29.297 64.4802 29.7373C64.2205 30.1776 63.836 30.5329 63.3266 30.8031C62.8173 31.0733 62.208 31.2084 61.4989 31.2084Z"
				fill="url(#paint0_linear_1831_3844)"
			/>
			<path
				d="M70.9411 20.6103V31.1033H69.8924V20.6103H70.9411Z"
				fill="url(#paint1_linear_1831_3844)"
			/>
			<path
				d="M84.5517 23.6727C84.2521 22.9821 83.7926 22.4417 83.1734 22.0514C82.5642 21.6511 81.8451 21.451 81.0161 21.451C80.2371 21.451 79.538 21.6311 78.9188 21.9914C78.2995 22.3517 77.8102 22.8671 77.4506 23.5376C77.0911 24.1981 76.9113 24.9687 76.9113 25.8493C76.9113 26.73 77.0911 27.5056 77.4506 28.1761C77.8102 28.8466 78.2995 29.362 78.9188 29.7223C79.538 30.0826 80.2371 30.2627 81.0161 30.2627C81.7452 30.2627 82.3994 30.1076 82.9787 29.7973C83.5679 29.4771 84.0373 29.0268 84.3869 28.4463C84.7464 27.8559 84.9512 27.1703 85.0011 26.3897H80.5967V25.5341H86.1097V26.2997C86.0598 27.2204 85.8101 28.056 85.3607 28.8066C84.9112 29.5472 84.307 30.1326 83.548 30.5629C82.7989 30.9933 81.955 31.2084 81.0161 31.2084C80.0473 31.2084 79.1685 30.9833 78.3794 30.5329C77.5904 30.0726 76.9662 29.4371 76.5068 28.6265C76.0574 27.8058 75.8326 26.8801 75.8326 25.8493C75.8326 24.8185 76.0574 23.8978 76.5068 23.0872C76.9662 22.2666 77.5904 21.6311 78.3794 21.1808C79.1685 20.7204 80.0473 20.4902 81.0161 20.4902C82.1347 20.4902 83.1135 20.7704 83.9524 21.3309C84.7914 21.8913 85.4006 22.6719 85.7801 23.6727H84.5517Z"
				fill="url(#paint2_linear_1831_3844)"
			/>
			<path
				d="M98.922 31.1033H97.8733L92.0756 22.2766V31.1033H91.027V20.6103H92.0756L97.8733 29.4221V20.6103H98.922V31.1033Z"
				fill="url(#paint3_linear_1831_3844)"
			/>
			<path
				d="M110.361 28.6114H105.597L104.683 31.1033H103.574L107.395 20.7454H108.578L112.383 31.1033H111.275L110.361 28.6114ZM110.046 27.7408L107.979 22.0664L105.911 27.7408H110.046Z"
				fill="url(#paint4_linear_1831_3844)"
			/>
			<path
				d="M123.19 20.6103V21.481H120.284V31.1033H119.235V21.481H116.314V20.6103H123.19Z"
				fill="url(#paint5_linear_1831_3844)"
			/>
			<path
				d="M128.846 20.6103V27.2154C128.846 28.2562 129.09 29.0268 129.58 29.5271C130.079 30.0275 130.758 30.2777 131.617 30.2777C132.476 30.2777 133.145 30.0325 133.625 29.5422C134.114 29.0418 134.359 28.2662 134.359 27.2154V20.6103H135.408V27.2004C135.408 28.5314 135.053 29.5321 134.344 30.2027C133.645 30.8732 132.731 31.2084 131.602 31.2084C130.474 31.2084 129.555 30.8732 128.846 30.2027C128.147 29.5321 127.797 28.5314 127.797 27.2004V20.6103H128.846Z"
				fill="url(#paint6_linear_1831_3844)"
			/>
			<path
				d="M146.316 31.1033L143.724 26.72H141.791V31.1033H140.743V20.6103H143.889C145.057 20.6103 145.941 20.8905 146.541 21.451C147.15 22.0114 147.454 22.747 147.454 23.6577C147.454 24.4182 147.235 25.0687 146.795 25.6091C146.366 26.1396 145.727 26.4848 144.878 26.6449L147.574 31.1033H146.316ZM141.791 25.8643H143.904C144.723 25.8643 145.337 25.6642 145.747 25.2639C146.166 24.8636 146.376 24.3282 146.376 23.6577C146.376 22.9671 146.176 22.4367 145.776 22.0664C145.377 21.6861 144.748 21.496 143.889 21.496H141.791V25.8643Z"
				fill="url(#paint7_linear_1831_3844)"
			/>
			<path
				d="M153.58 21.466V25.384H157.55V26.2546H153.58V30.2327H158V31.1033H152.532V20.5953H158V21.466H153.58Z"
				fill="url(#paint8_linear_1831_3844)"
			/>
			<path
				d="M49.1528 20.7948C48.8498 20.703 48.5048 20.8482 48.355 21.1301C47.5753 22.6193 46.3305 24.405 44.8881 24.3338C42.8904 24.2656 41.0101 20.9699 40.4521 19.7062C40.4402 19.6766 40.419 19.6588 40.4042 19.6379C40.3743 19.5905 40.3442 19.54 40.3023 19.4985C40.2724 19.4689 40.2364 19.448 40.2004 19.4273C40.1642 19.4066 40.1373 19.371 40.0983 19.3532H40.0833C40.0412 19.3354 39.9933 19.3294 39.9454 19.3236C39.9034 19.3145 39.8674 19.2998 39.8253 19.2998C39.7833 19.2998 39.7473 19.3145 39.7084 19.3234C39.6573 19.3294 39.6094 19.3352 39.5674 19.353H39.5494C39.5134 19.3708 39.4893 19.4004 39.4565 19.4182C39.4205 19.4451 39.3785 19.4629 39.3454 19.4985C39.3034 19.54 39.2764 19.5874 39.2465 19.6379C39.2314 19.6586 39.2105 19.6764 39.1985 19.7062C38.6376 20.9699 36.7603 24.2656 34.7657 24.3338C33.3322 24.4168 32.0784 22.6193 31.2956 21.1301C31.1456 20.8453 30.8037 20.6999 30.4918 20.7948C30.1799 20.8867 29.973 21.1863 30.0029 21.5068L30.9778 31.7857C31.0108 32.1358 31.3048 32.3998 31.6617 32.3998L39.8253 32.3996H47.9922C48.343 32.3996 48.643 32.1356 48.6729 31.7854L49.6476 21.5066C49.6746 21.1863 49.4708 20.8867 49.1529 20.7948L49.1528 20.7948ZM47.3711 31.0498H32.2853L31.6255 24.0696C32.6182 25.1849 33.6889 25.7428 34.8135 25.6923C37.138 25.6091 38.9226 23.0818 39.8253 21.4828C40.728 23.0818 42.5125 25.6092 44.8368 25.6892C45.9765 25.7397 47.0322 25.182 48.0249 24.0665L47.3711 31.0498Z"
				fill="url(#paint9_linear_1831_3844)"
			/>
			<defs>
				<filter
					id="filter0_d_1831_3844"
					x="0"
					y="0"
					width="188"
					height="57"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="3" />
					<feGaussianBlur stdDeviation="5" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_1831_3844"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_1831_3844"
						result="shape"
					/>
				</filter>
				<linearGradient
					id="paint0_linear_1831_3844"
					x1="58.7954"
					y1="20.9418"
					x2="58.7691"
					y2="32.3607"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#857D4C" />
					<stop offset="0.161458" stopColor="#BD9A64" />
					<stop offset="0.46875" stopColor="#FBDE98" />
					<stop offset="0.802083" stopColor="#BD9A64" />
					<stop offset="1" stopColor="#857D4C" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_1831_3844"
					x1="58.7954"
					y1="20.9418"
					x2="58.7691"
					y2="32.3607"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#857D4C" />
					<stop offset="0.161458" stopColor="#BD9A64" />
					<stop offset="0.46875" stopColor="#FBDE98" />
					<stop offset="0.802083" stopColor="#BD9A64" />
					<stop offset="1" stopColor="#857D4C" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_1831_3844"
					x1="58.7954"
					y1="20.9418"
					x2="58.7691"
					y2="32.3607"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#857D4C" />
					<stop offset="0.161458" stopColor="#BD9A64" />
					<stop offset="0.46875" stopColor="#FBDE98" />
					<stop offset="0.802083" stopColor="#BD9A64" />
					<stop offset="1" stopColor="#857D4C" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_1831_3844"
					x1="58.7954"
					y1="20.9418"
					x2="58.7691"
					y2="32.3607"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#857D4C" />
					<stop offset="0.161458" stopColor="#BD9A64" />
					<stop offset="0.46875" stopColor="#FBDE98" />
					<stop offset="0.802083" stopColor="#BD9A64" />
					<stop offset="1" stopColor="#857D4C" />
				</linearGradient>
				<linearGradient
					id="paint4_linear_1831_3844"
					x1="58.7954"
					y1="20.9418"
					x2="58.7691"
					y2="32.3607"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#857D4C" />
					<stop offset="0.161458" stopColor="#BD9A64" />
					<stop offset="0.46875" stopColor="#FBDE98" />
					<stop offset="0.802083" stopColor="#BD9A64" />
					<stop offset="1" stopColor="#857D4C" />
				</linearGradient>
				<linearGradient
					id="paint5_linear_1831_3844"
					x1="58.7954"
					y1="20.9418"
					x2="58.7691"
					y2="32.3607"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#857D4C" />
					<stop offset="0.161458" stopColor="#BD9A64" />
					<stop offset="0.46875" stopColor="#FBDE98" />
					<stop offset="0.802083" stopColor="#BD9A64" />
					<stop offset="1" stopColor="#857D4C" />
				</linearGradient>
				<linearGradient
					id="paint6_linear_1831_3844"
					x1="58.7954"
					y1="20.9418"
					x2="58.7691"
					y2="32.3607"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#857D4C" />
					<stop offset="0.161458" stopColor="#BD9A64" />
					<stop offset="0.46875" stopColor="#FBDE98" />
					<stop offset="0.802083" stopColor="#BD9A64" />
					<stop offset="1" stopColor="#857D4C" />
				</linearGradient>
				<linearGradient
					id="paint7_linear_1831_3844"
					x1="58.7954"
					y1="20.9418"
					x2="58.7691"
					y2="32.3607"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#857D4C" />
					<stop offset="0.161458" stopColor="#BD9A64" />
					<stop offset="0.46875" stopColor="#FBDE98" />
					<stop offset="0.802083" stopColor="#BD9A64" />
					<stop offset="1" stopColor="#857D4C" />
				</linearGradient>
				<linearGradient
					id="paint8_linear_1831_3844"
					x1="58.7954"
					y1="20.9418"
					x2="58.7691"
					y2="32.3607"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#857D4C" />
					<stop offset="0.161458" stopColor="#BD9A64" />
					<stop offset="0.46875" stopColor="#FBDE98" />
					<stop offset="0.802083" stopColor="#BD9A64" />
					<stop offset="1" stopColor="#857D4C" />
				</linearGradient>
				<linearGradient
					id="paint9_linear_1831_3844"
					x1="48.166"
					y1="22.4237"
					x2="32.0353"
					y2="32.6243"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#857D4C" />
					<stop offset="0.254413" stopColor="#BD9A64" />
					<stop offset="0.497128" stopColor="#FBDE98" />
					<stop offset="0.743825" stopColor="#BD9A64" />
					<stop offset="1" stopColor="#857D4C" />
				</linearGradient>
			</defs>
		</svg>
	);
};
