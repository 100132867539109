import clevertap from "clevertap-web-sdk";
import { memo, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactStars from "react-rating-stars-component";

// react-slick
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// icons
import { SvgArrowLeft as ArrowLeft } from "assets/icons/js/SvgArrowLeft";
import { SvgArrowRight as ArrowRight } from "assets/icons/js/SvgArrowRight";
import { SvgBlankHeart as BlankHeart } from "assets/icons/js/SvgBlankHeart";
import { SvgBreakfast } from "assets/icons/js/SvgBreakfast";
import { SvgFilledHeart as FilledHeart } from "assets/icons/js/SvgFilledHeart";
import { SvgLocation as Location } from "assets/icons/js/SvgLocation";
import { SvgNewHome } from "assets/icons/js/SvgNewHome";
import { SvgPetFriendly } from "assets/icons/js/SvgPetFriendly";
import { SvgRare } from "assets/icons/js/SvgRare";
import { SvgSignatureTag as SignatureTag } from "assets/icons/js/SvgSignatureTag";
import { SvgCouple } from "assets/icons/js/SvgCouple";
import { SvgFamily } from "assets/icons/js/SvgFamily";
import { SvgPool } from "assets/icons/js/SvgPool";
import { SvgVeg } from "assets/icons/js/SvgVeg";
import SvgWifi from "assets/icons/js/SvgWifi";

// helpers
import addCommas from "helpers/addCommasToNumber";
import { AddingToCollection } from "helpers/addToCollection";
import parseHTML from "helpers/parseHTML";

// context
import { SearchPageDataInterface } from "context/SearchPageData";

// customHooks
import { useMediaQuery } from "customHooks/useMediaQuery";
import { useUser } from "customHooks/useUser";

import { UserContext } from "store";

// style
import "./style.scss";
import { SvgNewhomesDis } from "assets/icons/js/SvgNewhomesDis";
import getImg from "helpers/getCloudinaryImage";

export const FeaturedCards = memo(
	({
		id,
		title,
		location,
		img,
		numOfNights,
		benefits,
		rating,
		reviews,
		price,
		signature,
		newHome,
		xseries,
		discountPrice,
		setShouldShowRemoveModal,
		minRooms,
		type,
		noOfBedrooms,
		capacity,
		saved,
		available,
		minCap,
		inCollection,
		hidePrice = false,
		seoURL = "villa",
		prevLocation = "",
		hideAvail = true,
		newHomeDis,
		altText,
		checkin,
		checkout,
	}) => {
		const { setShowLoginPopup, query } = useContext(SearchPageDataInterface);

		const [save, setSave] = useState(inCollection);

		// check if the user exists
		const user = useUser();
		const { state } = useContext(UserContext);
		const { currentUser } = state;

		const { pathname } = useLocation();
		const isSearchPage = pathname === "/search" ? true : false;
		const isHomePage = pathname === "/" ? true : false;

		const width = useMediaQuery();
		const isMobile = width <= "500" ? true : false;

		const imageList = img[0];

		// settings for image carousel
		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			prevArrow: <ArrowLeft />,
			nextArrow: <ArrowRight />,
			dotsClass: "button__bar",
			lazyLoad: true,
		};

		const nanCheck = isNaN(rating) === true ? 0 : +rating;

		const cardTop = (
			<div className="featured__card-img">
				{!isHomePage && (
					<div
						className="featured-card-heartIcon"
						onClick={() => {
							clevertap.event.push("add__to__collection__search__page", {
								listing_id: id,
								listing_name: title,
								timestamp: new Date(),
								no_of_bedrooms: noOfBedrooms,
								no_of_guests: capacity,
								checkin_date: query.get("checkin") || "",
								checkout_date: query.get("checkout") || "",
								home_state: state,
								image_url: imageList,
								price: price,
							});
							if (!user) {
								setShowLoginPopup(true);
							}
							if (user) {
								setSave((prev) => !prev);
								AddingToCollection(id, save);
							}
						}}
					>
						{!save ? (
							<span>
								<BlankHeart />
								<p>Save</p>
							</span>
						) : (
							<span>
								<FilledHeart />
								<p>Saved</p>
							</span>
						)}
					</div>
				)}
				<div className="featured-card-signature">
					<span className="icon-signature">
						{signature !== 0 && <SignatureTag height={isMobile ? 47 : 57} />}
					</span>
					<span className="icon-newHome">
						{newHome && <SvgNewHome height={isMobile ? 47 : 57} />}
					</span>
					{/* {discountPrice && price !== discountPrice && (
						<div className="icon-sale">
							<span className="sale">Pre-Festive deal ends today</span>
						</div>
					)} */}
					{!newHome && discountPrice && discountPrice - price > 5000 && (
						<div className="icon-rare">
							<SvgRare />
						</div>
					)}
					{newHomeDis && (
						<div className="icon-rare">
							<SvgNewhomesDis />
						</div>
					)}

					{!hideAvail &&
						(pathname === "/search" ||
							pathname.split("/")[1] === "collection" ||
							pathname.split("/")[1] === "villas" ||
							pathname === "/") && (
							<div className="icon-avail">
								{available === 1 ? (
									<span className="avail">Available</span>
								) : available === 2 ? (
									<span className="avail">Available today</span>
								) : (
									<span className="not-avail">Not available</span>
								)}
							</div>
						)}
				</div>
				<div className="featured-card-img-box">
					<Slider {...settings}>
						{img?.map((value, i) => {
							return (
								<img
									src={value}
									key={i}
									alt={
										altText
											? altText
											: window.location.pathname.includes("villas/")
											? `${parseHTML(title)}, villa in ${
													query.get("location") ||
													window.location.pathname?.split("/")[2]?.split("-")[2]
											  }`
											: parseHTML(title)
									}
									onClick={() => {
										window.open(
											`/view/${seoURL + "-"}${id}?adults=${
												query?.get("guests") || ""
											}&children=${query?.get("children") || ""}&infants=${
												query?.get("infants") || ""
											}&rooms=${query?.get("rooms") || ""}
											&checkin=${query?.get("checkin") ? query.get("checkin") : checkin}&checkout=${
												query?.get("checkout")
													? query.get("checkout")
													: checkout
											}`,
											width <= "800" ? "" : "_blank",
										);
										clevertap.event.push("featured_card_website", {
											from: prevLocation,
											listing_name: `${title}`,
											listing_url: `${seoURL + "-"}${id}`,
										});
										if (discountPrice) {
											clevertap.event.push("rare_price_tag", {
												from: prevLocation,
												listing_name: `${title}`,
												listing_url: `${seoURL + "-"}${id}`,
											});
										}
									}}
								/>
							);
						})}
					</Slider>
				</div>
			</div>
		);

		const cardMiddle = (
			<div className="featured__card-middle">
				<div className="featured__card-context-container">
					<div className="featured__card-title">
						<h2>{parseHTML(title)}</h2>
					</div>
					<div className="featured__card-location">
						<span>
							<Location />
						</span>
						<p>{location}</p>
					</div>
					<div className="featured__card-type">
						<div>
							<p>
								{minCap && minCap * minRooms !== capacity
									? `${minCap * minRooms} -`
									: ""}{" "}
								{capacity} guests
							</p>
						</div>
						<div>
							<p>
								{type !== "Separate Villas"
									? `${noOfBedrooms} Bedroom`
									: `${noOfBedrooms} Villas`}
							</p>
						</div>
						<div>
							<p>{type}</p>
						</div>
					</div>
					<div className="featured__card-benefits">
						{(benefits?.includes("WiFi Broadband") ||
							benefits?.includes("WiFi Dongle") ||
							benefits?.includes("WiFi Fibre Or WiFi Cable")) && (
							<div data-title="wifi">
								<SvgWifi />
							</div>
						)}
						{benefits?.includes("Swimming Pool") && (
							<div data-title="pool">
								<SvgPool />
							</div>
						)}
						{benefits?.includes("Veg Only") && (
							<div data-title="veg only">
								<SvgVeg />
							</div>
						)}
						{benefits?.includes("Pet Friendly") && (
							<div data-title="pet friendly">
								<SvgPetFriendly />
							</div>
						)}
						{benefits?.includes("Couple") && (
							<div data-title="couple">
								<SvgCouple />
							</div>
						)}
						{benefits?.includes("Single Family") && (
							<div data-title="single family">
								<SvgFamily />
							</div>
						)}
						{benefits?.includes("Breakfast Included In Tariff") && (
							<div className="breakfast-icon">
								<div className="icon">
									<SvgBreakfast />
								</div>
								<p>Breakfast Included</p>
							</div>
						)}
						{/* {benefits?.includes("2 or 3 Families") && (
						<div>
							<SvgPetFriendly />
						</div>
					)} */}
					</div>
					<div className="featured__card-reviews">
						<>
							{reviews !== 0 && (
								<>
									<div className="reviews-rating">
										<p>{nanCheck}</p>
									</div>
									<div className="reviews-stars">
										<ReactStars
											size={14}
											value={nanCheck}
											activeColor="#FBA919"
											color="#DDDDDD"
											isHalf={true}
											edit={false}
										/>
									</div>
								</>
							)}
							<div className="no-reviews">
								<p style={{ height: reviews === 0 ? "20px" : "" }}>
									{reviews === 0 ? "" : `${reviews} reviews`}{" "}
								</p>
							</div>
						</>
					</div>
				</div>

				{currentUser.isTravelAgent ? (
					<div className="featured__card-ta-container">
						<div className="featured__card-ta-user">
							<p className="featured__card-ta-user-para">
								TAC on this home{" "}
								{currentUser.discountPercentage !== ""
									? `(${currentUser.discountPercentage}%)`
									: ""}
							</p>
							<p className="featured__card-ta-user-price">
								₹{" "}
								{currentUser.amount === 0
									? addCommas(
											((currentUser.discountPercentage / 100) * price).toFixed(
												2,
											),
									  )
									: addCommas(currentUser.amount)}
							</p>
						</div>
					</div>
				) : (
					""
				)}
			</div>
		);

		const cardBottom = (
			<div className="featured__card-bottom">
				<div className="featured__card-button">
					{!hidePrice && (
						<div>
							<div>
								<h2>
									₹ {addCommas(price)}
									<p>(+ GST)</p>
								</h2>
								{pathname === "/search" ||
								pathname.split("/")[1] === "villas" ? (
									<p>for {numOfNights} nights</p>
								) : (
									""
								)}
							</div>
							{discountPrice && price !== discountPrice && (
								<span className="featured__card-discount">
									₹ {addCommas(discountPrice)}
									<hr />
								</span>
							)}
						</div>
					)}
					<button
						aria-label="view-details"
						className="link-button"
						type="button"
					>
						View Details
					</button>
				</div>
			</div>
		);

		return (
			<>
				<div
					className="featured__card"
					style={{ zIndex: isSearchPage ? "1 " : "0" }}
				>
					{cardTop}
					<a
						aria-label={parseHTML(title)}
						href={`/view/${seoURL + "-"}${id}?adults=${
							query?.get("guests") || ""
						}&children=${query?.get("children") || ""}&infants=${
							query?.get("infants") || ""
						}&rooms=${query?.get("rooms") || ""}
						&checkin=${query?.get("checkin") ? query.get("checkin") : checkin}&checkout=${
							query?.get("checkout") ? query.get("checkout") : checkout
						}`}
						target={width <= 800 ? "" : "_blank"}
						rel={width <= 800 ? "" : "noreferrer noopener"}
						className="card-link"
						onClick={() => {
							clevertap.event.push("featured_card_website", {
								from: prevLocation,
								listing_name: `${title}`,
								listing_url: `${seoURL + "-"}${id}`,
							});
							if (discountPrice) {
								clevertap.event.push("rare_price_tag", {
									from: prevLocation,
									listing_name: `${title}`,
									listing_url: `${seoURL + "-"}${id}`,
								});
							}
						}}
					>
						{cardMiddle}
						{cardBottom}
					</a>
				</div>
			</>
		);
	},
);
