import React from "react";

export const SvgBlankHeart = (props) => {
	return (
		<svg
			width="17"
			height="15"
			viewBox="0 0 17 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.84534 2.18506C10.0689 2.00642 10.345 1.80748 10.6768 1.58948C10.9531 1.40763 11.2538 1.26177 11.5814 1.15256C11.8971 1.0474 12.1905 1 12.4653 1C13.6928 1 14.5259 1.33459 15.0966 1.89187C15.6629 2.44488 16 3.24915 16 4.43964C16 5.46862 15.47 6.64999 14.127 7.99491L8.50034 13.4139L2.85732 7.96974L2.82365 7.93725L2.7871 7.90806C2.7871 7.90806 2.78605 7.90722 2.78361 7.90516L2.77337 7.89629C2.76439 7.88835 2.75246 7.87747 2.73729 7.86314C2.70677 7.83429 2.66779 7.7958 2.61945 7.74615C2.5453 7.66965 2.39587 7.49614 2.15651 7.19691C1.94298 6.93 1.7537 6.65826 1.58805 6.38216C1.44509 6.14389 1.30387 5.83209 1.17238 5.43466C1.05347 5.07535 1 4.74489 1 4.43967C1 3.24911 1.33703 2.44487 1.9034 1.89189C2.47404 1.33468 3.30716 1 4.53454 1C4.80917 1 5.10282 1.04749 5.41854 1.15263M9.84534 2.18506C9.62117 2.36452 9.40829 2.55501 9.20705 2.75622M9.84534 2.18506L9.20705 2.75622M5.41854 1.15263C5.74612 1.2619 6.04695 1.40775 6.3235 1.58969M5.41854 1.15263L6.3235 1.58969M9.20705 2.75622L8.50015 3.46301M8.50015 3.46301L7.7931 2.75637C7.59154 2.55493 7.37874 2.36446 7.15457 2.18504M8.50015 3.46301L7.15457 2.18504M7.15457 2.18504C6.93147 2.0067 6.65557 1.80809 6.3235 1.58969M7.15457 2.18504L6.3235 1.58969"
				stroke="#333333"
				strokeWidth="2"
				{...props}
			/>
		</svg>
	);
};
