import { BookingInterface } from "context/BookingData";
import { axiosInstance } from "helpers/axiosInstance";
import moment from "moment";
import React, { useContext, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "./style.scss";
import { useMutation } from "react-query";
import { ReactComponent as UnLockIcon } from "../assets/unlock.svg";
import { ReactComponent as GiftIcon } from "../assets/gift.svg";
import { ReactComponent as InfoIcon } from "../assets/info.svg";
import { ReactComponent as TickIcon } from "assets/green_tick.svg";

import clevertap from "clevertap-web-sdk";
import { UserContext } from "store";
import axios from "axios";

const DeadlineCoupon = ({ data, from = false }: any) => {
	const { updateBooking, fetchData, bookingData }: any =
		useContext(BookingInterface);

	const [searchParams, setSearchParams] = useSearchParams();
	const [time, setTime] = useState(new Date());
	const [err, setErr] = useState(false);
	const [extendReq, setExtendReq] = useState(false);

	useEffect(() => {
		if (sessionStorage.getItem("extend_coupon")) {
			setExtendReq(true);
		}
	}, []);

	const {
		state: { currentUser },
	} = useContext(UserContext);

	useEffect(() => {
		if (
			new Date(fetchData?.data?.data?.data?.EXPIRY_DATE)?.getTime() -
				time.getTime() <
			0
		) {
			window.location.reload();
			return;
		}

		let timer = setInterval(() => {
			setTime(new Date());
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, [time]);

	const createDis = useMutation(
		() => {
			return axiosInstance({
				method: "post",
				url: "/create/deadline-coupon",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
				data: {
					totalAmount: data.TOTAL_AMOUNT_WITH_SERVICE,
					BOOKING_ID: data.BOOKING_ID,
				},
			});
		},
		{
			onSuccess: (res) => {
				clevertap.profile.push({
					Site: {
						Phone: "+" + currentUser.cc + currentUser.mobile,
						"MSG-whatsapp": true,
					},
				});
				clevertap.event.push("unlock_best_offer", {
					phone: currentUser.mobile,
					email: currentUser.email,

					bookingValue: data.TOTAL_AMOUNT_WITH_SERVICE,
					couponCode: res.data.data.COUPON_CODE,
				});
				localStorage.setItem(
					"unlock_best_offer",
					JSON.stringify({
						couponCode: res.data.data.COUPON_CODE,
						expireAt: res.data.data.EXPIRY_DATE,
						discount: res.data.data.DISCOUNT,
					}),
				);
				fetchData.refetch();
			},
			onError: (error) => {
				localStorage.removeItem("unlock_best_offer");
				// @ts-ignore
				if (error?.response?.data?.error) {
					// @ts-ignore
					// setError(error.response.data.error);
					setErr(true);
				}
			},
		},
	);

	const extendCoupon = async () => {
		try {
			const res = await axios.post(
				"https://go.saffronstays.com/api/request-deadline-coupon",
				{
					phone: currentUser.mobile,
					email: currentUser.email,
					villa: data?.VILLA_NAME.split("-")[0],
					checkIn: data?.FROM_DATE,
					checkOut: data?.TO_DATE,
					name: currentUser.firstName + " " + currentUser.lastName,
					bookingId: data?.BOOKING_ID,
					budget: data?.TOTAL_AMOUNT_WITH_SERVICE,
					source: "booking",
				},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				},
			);
			if (res.data.success) {
				setExtendReq(true);
				sessionStorage.setItem("extend_coupon", "true");
			}
		} catch (err) {}
	};

	if (err) {
		return (
			<>
				<div className="deadline-coupon-expire">
					<div className="cross">x</div>
					<span>
						Uh oh! Your special coupon has now expired.{" "}
						{extendReq ? (
							<span className="extended-req">
								<TickIcon /> Request Sent
							</span>
						) : (
							<button onClick={extendCoupon}>Extend Coupon</button>
						)}
					</span>
				</div>
				{extendReq && (
					<div className="extend-req-note">
						We’ve sent a request to extend your Coupon. You’ll receive a
						WhatsApp/Email on approval of extension
					</div>
				)}
			</>
		);
	}

	if (
		new Date(fetchData?.data?.data?.data?.EXPIRY_DATE)?.getTime() -
			time.getTime() <
		0
	) {
		return (
			<>
				<div className="deadline-coupon-expire">
					<div className="cross">x</div>
					<span>
						Uh oh! Your special coupon has now expired.{" "}
						{extendReq ? (
							<span className="extended-req">
								<TickIcon /> Request Sent
							</span>
						) : (
							<button onClick={extendCoupon}>Extend Coupon</button>
						)}
					</span>
				</div>
				{extendReq && (
					<div className="extend-req-note">
						We’ve sent a request to extend your Coupon. You’ll receive a
						WhatsApp/Email on approval of extension
					</div>
				)}
			</>
		);
	}

	return data?.REWARDPOINTS_APPLICABLE ? (
		!fetchData?.data?.data?.data?.COUPON_CODE ? (
			<div
				className="deadline-coupon-wrapper"
				onClick={() => {
					createDis.mutateAsync();
				}}
			>
				<UnLockIcon /> Click to Unlock Best Price!!{" "}
			</div>
		) : (
			<>
				{!currentUser?.isTravelAgent && (
					<>
						<div className="deadline-coupon-apply">
							<div className="deadline-percentage">
								Get {fetchData?.data?.data?.data?.DISCOUNT}% OFF
								<GiftIcon />
							</div>
							<div className="deadline-details">
								<div className="top-detail">
									<div>
										Use your special coupon code{" "}
										<b>{fetchData?.data?.data?.data?.COUPON_CODE}</b>
										&nbsp;while booking
									</div>
									<span
										style={{
											color:
												data.COUPON_CODE ===
												fetchData?.data?.data?.data?.COUPON_CODE
													? "#333"
													: "",
										}}
										onClick={async (e: any) => {
											await updateBooking.mutateAsync({
												COUPON_CODE: fetchData?.data?.data?.data?.COUPON_CODE,
											});
											searchParams.set(
												"coupon",
												fetchData?.data?.data?.data?.COUPON_CODE,
											);
											setSearchParams(searchParams, { replace: true });
										}}
									>
										{data.COUPON_CODE ===
										fetchData?.data?.data?.data?.COUPON_CODE
											? "Applied"
											: "Apply"}
									</span>
								</div>
								<div className="bottom-detail">
									Expires in{" "}
									<span>
										{moment(
											new Date(
												fetchData?.data?.data?.data?.EXPIRY_DATE,
											)?.getTime() -
												time.getTime() -
												19800000,
										).format("HH:mm:ss")}
									</span>
								</div>
							</div>
						</div>
						<div
							className={`info-deadline ${from === "payment" && "info-mobile"}`}
							style={{ marginTop: -20, marginBottom: 20 }}
						>
							<InfoIcon /> No refunds or re-schedule for bookings under this
							coupon
						</div>
					</>
				)}
			</>
		)
	) : null;
};

export default DeadlineCoupon;
