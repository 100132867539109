import { SvgLongArrow } from "assets/icons/js/SvgLongArrow";

// style
import "./style.scss";

export const HomeScrollContainerHeader = ({
	heading,
	slideLeft,
	slideRight,
	reachedLeft,
	reachedRight,
}: any) => {
	return (
		<div className="home__scroll__header__container">
			<div className="scroll__header">
				<div className="scroll__title">
					<h1 className="title">{heading}</h1>
				</div>
				<div className="scroll__button">
					<span className="button__one" onClick={slideLeft}>
						{reachedLeft ? (
							<SvgLongArrow className="left__arrow not__active" />
						) : (
							<SvgLongArrow className="left__arrow active" />
						)}
					</span>
					<span className="button__two" onClick={slideRight}>
						{!reachedRight ? (
							<SvgLongArrow className="right__arrow active" />
						) : (
							<SvgLongArrow className="right__arrow not__active" />
						)}
					</span>
				</div>
			</div>
		</div>
	);
};
