export const SvgCouple = () => {
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 22 18"
			id="two-people"
		>
			<path
				d="M6.3635 8.32054C8.58649 8.32054 10.395 6.51206 10.395 4.28907C10.395 2.06608 8.58653 0.257599 6.3635 0.257599C4.14051 0.257599 2.33203 2.06608 2.33203 4.28907C2.33203 6.51206 4.14051 8.32054 6.3635 8.32054ZM6.3635 1.75149C7.76272 1.75149 8.90103 2.88981 8.90103 4.28899C8.90103 5.68816 7.76272 6.82648 6.3635 6.82648C4.96428 6.82648 3.82601 5.68816 3.82601 4.28899C3.82601 2.88985 4.96428 1.75149 6.3635 1.75149Z"
				fill="#666666"
				stroke="#666666"
				strokeWidth="0.25"
			/>
			<path
				d="M15.637 8.32054C17.86 8.32054 19.6685 6.51206 19.6685 4.28907C19.6685 2.06608 17.86 0.257599 15.637 0.257599C13.414 0.257599 11.6055 2.06608 11.6055 4.28907C11.6055 6.51202 13.414 8.32054 15.637 8.32054ZM15.637 1.75149C17.0362 1.75149 18.1745 2.88981 18.1745 4.28899C18.1745 5.68816 17.0362 6.82648 15.637 6.82648C14.2378 6.82648 13.0994 5.68816 13.0994 4.28899C13.0995 2.88985 14.2378 1.75149 15.637 1.75149Z"
				fill="#666666"
				stroke="#666666"
				strokeWidth="0.25"
			/>
			<path
				d="M11.0001 11.244C7.51194 6.89886 0.412109 9.36178 0.412109 14.9771V16.9954C0.412109 17.408 0.746549 17.7424 1.15908 17.7424H11.5676H20.8416C21.2541 17.7424 21.5886 17.408 21.5886 16.9954V14.9771C21.5886 9.36005 14.4838 6.90258 11.0001 11.244ZM10.8206 16.2484H1.90608V14.9771C1.90608 12.5194 3.90569 10.5201 6.36357 10.5201C8.84469 10.5201 10.8206 12.5275 10.8206 14.9771V16.2484H10.8206ZM20.0946 16.2484H12.3145C12.3145 14.8524 12.4034 13.9704 11.8388 12.6427C12.6478 11.3249 14.0728 10.5201 15.6371 10.5201C18.095 10.5201 20.0946 12.5194 20.0946 14.9771V16.2484H20.0946Z"
				fill="#666666"
				stroke="#666666"
				strokeWidth="0.25"
			/>
		</svg>
	);
};
