import { axiosInstance, axiosInstance2 } from "helpers/axiosInstance";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import clevertap from "clevertap-web-sdk";
export const BookingInterface = createContext([]);

const useSearchQuery = () => {
	const { search } = useLocation();
	return useMemo(() => new URLSearchParams(search), [search]);
};

export const BookingData = ({ children }: any) => {
	const { id } = useParams();
	let query = useSearchQuery();
	const [searchParams, setSearchParams] = useSearchQuery();
	const [acceptTC, setAcceptTC] = useState(true);

	const getBookingData = useQuery(
		["bookingDetails", id], // Unique query key
		() => {
			return axiosInstance2({
				method: "GET",
				url: `/manageBookings/fetchBookingDetails?BOOKING_ID=${id}`,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			}).then((response) => response.data);
		},
		{},
	);
	const bookingData = getBookingData;

	const queryClient = useQueryClient();

	const updateBooking = useMutation(
		(body) => {
			return axiosInstance2({
				method: "post",
				url: "/manageBookings/updateBooking",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
				data: {
					BOOKING_ID: id,
					NUM_ROOMS: bookingData?.data.data?.NO_OF_ROOMS,
					NUM_ADULTS: bookingData?.data.data?.NO_OF_GUESTS,
					NUM_CHILD: bookingData?.data.data?.NUM_CHILDREN,
					NUM_INFANTS: bookingData?.data.data?.NUM_SMALL_CHILDREN,
					NUM_MAIDS: bookingData?.data.data?.NUMBER_OF_MAIDS,
					NUM_DRIVERS: bookingData?.data.data?.NUMBER_OF_DRIVERS,
					NUM_PETS: bookingData?.data.data?.NUMBER_OF_PETS,
					GST_TO_NAME: bookingData?.data.data?.GST_TO_NAME,
					APPLY_REWARDS: bookingData?.data.data?.ADJ_AGAINST_REWARD ? 1 : 0,
					COUPON_CODE: bookingData?.data.data?.COUPON_CODE,
					FROM_DATE: bookingData?.data.data?.FROM_DATE,
					TO_DATE: bookingData?.data.data?.TO_DATE,
					addMeals: bookingData?.data.data?.MEAL_CHARGES ? 1 : 0,
					...body,
				},
			});
		},
		{
			// Options
			onMutate: (variables: any) => {
				// Invalidate and refetch the booking details query when the mutation is triggered
				// queryClient.invalidateQueries(["bookingDetails", id]);
			},
			onSuccess: (res: any) => {
				// Optionally, you can perform additional actions on successful mutation
				// Here, we update the query key after a successful updates
				queryClient.invalidateQueries(["bookingDetails", id]);
				queryClient.invalidateQueries(["cancellation", id]);
				queryClient.invalidateQueries(["unlockDisData", id]);

				// queryClient.refetchQueries(["fetchCoupons", id]);
				// console.log(res?.data?.data);

				fetchCoupons.mutate(res?.data?.data);
			},
			onError: (err: any) => {
				toast.error(err.response.data.error, {
					position: window.innerWidth < 800 ? "top-right" : "bottom-right",
					autoClose: 2000,
					hideProgressBar: false,
					icon: true,
					closeOnClick: false,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
				});
			},
			// Other mutation options go here
		},
	);

	const fetchCoupons = useMutation(
		(data: any) =>
			// @ts-ignore
			axiosInstance2({
				method: "GET",
				url: `/ss3/fetch/coupons/new?FROM_DATE=${data.FROM_DATE}&TO_DATE=${data.TO_DATE}&LISTING_ID=${data?.LISTING_ID}&BOOKING_ID=${id}`,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			}),
		{
			onSuccess: (res) => {
				// queryClient.setQueryData(["fetchCoupons", id], res);
			},
			onError: (err: any) => {
				toast.error(err.response.data.error, {
					position: "bottom-right",
					autoClose: 2000,
					hideProgressBar: false,
					icon: true,
					closeOnClick: false,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
				});
			},
		},
	);

	const removeCoupon = useMutation(
		() => {
			// @ts-ignore
			return axiosInstance({
				method: "post",
				url: "/removeCoupon",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
				data: {
					bookingId: id,
				},
			});
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["bookingDetails", id]);
				//@ts-ignore
				searchParams.set("coupon", ""); //@ts-ignore
				setSearchParams(searchParams, { replace: true });
			},
		},
	);

	// applyCoupon applies the coupon
	const applyCoupon = useMutation(
		// @ts-ignore
		(data) => {
			// @ts-ignore
			return axiosInstance({
				method: "POST",
				url: "/applyCoupon",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
				data: {
					// @ts-ignore
					couponCode:
						// @ts-ignore
						query?.get("coupon") || data?.coupon,
					bookingId: id,
				},
			});
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["bookingDetails", id]);
			},
			onError: (error) => {
				removeCoupon.mutate();
				query.set("coupon", "");
			},
		},
	);

	const fetchPaymentGateway = useMutation(
		(data: any) => {
			// @ts-ignore
			return axiosInstance({
				method: "POST",
				url: "/payment/link/newpaymentLink",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
				data: {
					BOOKING_ID: id,
				},
			});
		},
		{
			onSuccess: (data) => {
				if (data.data.data.PAYMENT_LINK) {
					clevertap.event.push("proceed_to_payment", {
						ListingID: bookingData?.data.data?.LISTING_ID,
						NameOfTheListing: bookingData?.data.data?.VILLA_NAME,
						ListingpageURL: `https://www.saffronstays.com/view/${bookingData?.data.data?.LISTING_ID}`,
						BookingpageURL: window.location.href,
						BookingID: bookingData?.data.data?.BOOKING_ID,
						TotalAmount: bookingData?.data.data?.TOTAL_AMOUNT,
						DatesSelected: `${bookingData?.data.data?.FROM_DATE} - ${bookingData?.data.data?.TO_DATE}`,
						PetsSelected: bookingData?.data.data?.NUMBER_OF_PETS,
						MaidSelected: bookingData?.data.data?.NUMBER_OF_MAIDS,
						DriverSelected: bookingData?.data.data?.NUMBER_OF_DRIVERS,
						PaymentUrl: data?.data?.data?.PAYMENT_LINK,
						CustomCouponCode: bookingData?.data?.data?.COUPON_CODE,
						ImageURL: `https://ik.imagekit.io/5tgxhsqev/saffronstays-media/image/upload/${bookingData?.data?.data?.FIRST_IMAGE_ID}`,
						Timestamp: new Date(),
					});

					setTimeout(() => {
						window.location.href = data?.data?.data?.PAYMENT_LINK;
					}, 1500);
				} else {
					window.location.reload();
				}
			},
		},
	);
	const fetchData = useQuery(
		["unlockDisData", id],
		() => {
			return axiosInstance({
				method: "GET",
				url: `/fetch/deadline-coupon`,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
		},
		{
			retry: false,
			onSuccess: (res: any) => {
				localStorage.setItem(
					"unlock_best_offer",
					JSON.stringify({
						couponCode: res?.data?.data?.COUPON_CODE,
						expireAt: res?.data?.data?.EXPIRY_DATE,
						discount: res.data.data.DISCOUNT,
					}),
				);
			},
			onError: (err: any) => {
				localStorage.removeItem("unlock_best_offer");
			},
		},
	);

	const data: any = {
		bookingData,
		updateBooking,
		fetchCoupons,
		applyCoupon,
		removeCoupon,
		fetchPaymentGateway,
		acceptTC,
		setAcceptTC,
		fetchData,
	};

	return (
		<BookingInterface.Provider value={data}>
			<ToastContainer />
			{children}
		</BookingInterface.Provider>
	);
};
