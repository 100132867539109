import React from "react";

const SvgWifi = (props) => {
	return (
		<svg
			width="21"
			height="17"
			viewBox="0 0 21 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M3.41426 7.85727C2.9765 8.29504 2.9765 9.00748 3.41426 9.44954C3.85203 9.88731 4.56448 9.88731 5.00654 9.44954C8.03658 6.4195 12.9679 6.4195 15.998 9.44954C16.2168 9.66843 16.5044 9.78002 16.792 9.78002C17.0795 9.78002 17.3671 9.66843 17.5859 9.44954C18.0237 9.01178 18.0237 8.29933 17.5859 7.85727C13.6804 3.9474 7.31985 3.9474 3.41426 7.85727Z"
				fill="#999999"
			/>
			<path
				d="M6.45235 10.8959C6.01458 11.3337 6.01458 12.0461 6.45235 12.4882C6.89012 12.9259 7.60256 12.9259 8.04462 12.4882C9.39655 11.1319 11.6026 11.1319 12.9545 12.4882C13.1734 12.7071 13.4609 12.8186 13.7485 12.8186C14.036 12.8186 14.3236 12.7071 14.5425 12.4882C14.9802 12.0504 14.9802 11.3379 14.5425 10.9002C12.315 8.66412 8.6841 8.66412 6.45235 10.8959Z"
				fill="#999999"
			/>
			<path
				d="M10.4998 16.4367C11.327 16.4367 11.9977 15.7661 11.9977 14.9388C11.9977 14.1116 11.327 13.441 10.4998 13.441C9.67257 13.441 9.00195 14.1116 9.00195 14.9388C9.00195 15.7661 9.67257 16.4367 10.4998 16.4367Z"
				fill="#999999"
			/>
			<path
				d="M20.6717 4.76715C15.0622 -0.837992 5.93777 -0.837992 0.328326 4.76715C-0.109442 5.20492 -0.109442 5.91736 0.328326 6.35942C0.766094 6.79719 1.47854 6.79719 1.9206 6.35942C6.65021 1.62552 14.3498 1.62552 19.0837 6.35942C19.3026 6.57831 19.5901 6.6899 19.8777 6.6899C20.1652 6.6899 20.4528 6.57831 20.6717 6.35942C21.1094 5.92166 21.1094 5.20921 20.6717 4.76715Z"
				fill="#999999"
			/>
		</svg>
	);
};

export default SvgWifi;
