export const SvgArrowLeft = ({ currentSlide, slideCount, ...props }) => {
	return (
		<svg
			width="54"
			height="54"
			viewBox="0 0 54 54"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
			aria-hidden="true"
			aria-disabled={currentSlide === slideCount - 1 ? true : false}
			type="button"
		>
			<g filter="url(#filter0_d_1831_3956)">
				<rect
					width="24"
					height="24"
					rx="12"
					transform="matrix(-1 0 0 1 39 11)"
					fill="white"
				/>
			</g>
			<g clipPath="url(#clip0_1831_3956)">
				<path
					d="M24.5791 23.0009C24.5791 23.1531 24.6372 23.3053 24.7532 23.4214L28.4047 27.0729C28.637 27.3051 29.0136 27.3051 29.2458 27.0729C29.478 26.8407 29.478 26.4641 29.2458 26.2318L26.0147 23.0009L29.2457 19.77C29.4779 19.5377 29.4779 19.1612 29.2457 18.9291C29.0135 18.6967 28.6369 18.6967 28.4046 18.9291L24.753 22.5805C24.637 22.6966 24.5791 22.8488 24.5791 23.0009Z"
					fill="#333333"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_1831_3956"
					x="0"
					y="0"
					width="54"
					height="54"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="4" />
					<feGaussianBlur stdDeviation="7.5" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.4 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_1831_3956"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_1831_3956"
						result="shape"
					/>
				</filter>
				<clipPath id="clip0_1831_3956">
					<rect
						width="8.49231"
						height="8.49231"
						fill="white"
						transform="matrix(0 -1 -1 0 31.2456 27.2471)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
