// @ts-nocheck
import clevertap from "clevertap-web-sdk";
import { memo, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// react-slick
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// icons
import { SvgArrowLeft as ArrowLeft } from "assets/icons/js/SvgArrowLeft";
import { SvgArrowRight as ArrowRight } from "assets/icons/js/SvgArrowRight";
import { SvgBlankHeart as BlankHeart } from "assets/icons/js/SvgBlankHeart";
import { SvgBreakfast } from "assets/icons/js/SvgBreakfast";
import { SvgFilledHeart as FilledHeart } from "assets/icons/js/SvgFilledHeart";
import { SvgLocation as Location } from "assets/icons/js/SvgLocation";
import { SvgNewHome } from "assets/icons/js/SvgNewHome";
import { SvgPetFriendly } from "assets/icons/js/SvgPetFriendly";
import { SvgRare } from "assets/icons/js/SvgRare";
import { SvgSignatureTag as SignatureTag } from "assets/icons/js/SvgSignatureTag";

import { SvgCouple } from "assets/icons/js/SvgCouple";
import { SvgFamily } from "assets/icons/js/SvgFamily";
import { SvgPool } from "assets/icons/js/SvgPool";
import { SvgVeg } from "assets/icons/js/SvgVeg";

import SvgWifi from "assets/icons/js/SvgWifi";

// helpers
import addCommas from "helpers/addCommasToNumber";
import { AddingToCollection } from "helpers/addToCollection";
import parseHTML from "helpers/parseHTML";

// context
import { SearchPageDataInterface } from "context/SearchPageData";

// customHooks
import { useMediaQuery } from "customHooks/useMediaQuery";
import { useUser } from "customHooks/useUser";

import { UserContext } from "store";

// style
import "./style.scss";
import { SvgNewhomesDis } from "assets/icons/js/SvgNewhomesDis";
import { SvgStar } from "assets/icons/js/SvgStar";
import { SvgListingSignature } from "assets/icons/js/SvgListingSignature";
import { SvgListingNewHomes } from "assets/icons/js/SvgListingNewHomes";

export const HomeNewCard = ({
	id,
	title,
	location,
	img,
	numOfNights,
	benefits,
	rating,
	reviews,
	price,
	signature,
	newHome = true,
	xseries,
	discountPrice,
	setShouldShowRemoveModal,
	minRooms,
	type,
	noOfBedrooms,
	capacity,
	saved,
	available,
	minCap,
	inCollection,
	hidePrice = false,
	seoURL = "villa",
	prevLocation = "",
	hideAvail = true,
	newHomeDis,
	distance = 0,
	off = 0,
}: any) => {
	// @ts-ignore
	const { setShowLoginPopup, query } = useContext(SearchPageDataInterface);

	const [save, setSave] = useState(inCollection);

	// check if the user exists
	const user = useUser();
	const { state } = useContext(UserContext);
	const { currentUser } = state;

	const { pathname } = useLocation();
	const isSearchPage = pathname === "/search" ? true : false;
	const isHomePage = pathname === "/" ? true : false;

	const width = useMediaQuery();
	const isMobile = width <= 500 ? true : false;

	// settings for image carousel
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		// @ts-ignore
		prevArrow: <ArrowLeft />,
		// @ts-ignore
		nextArrow: <ArrowRight />,
		dotsClass: "button__bar",
		lazyLoad: true,
	};

	const nanCheck = isNaN(rating) === true ? 0 : +rating;

	const cardTop = (
		<div className="new__card__img">
			{!isHomePage && (
				<div
					className="new__card__heart"
					onClick={() => {
						if (!user) {
							setShowLoginPopup(true);
						}
						if (user) {
							setSave((prev) => !prev);
							AddingToCollection(id, save);
						}
					}}
				>
					{!save ? (
						<span>
							<BlankHeart />
							<p>Save</p>
						</span>
					) : (
						<span>
							<FilledHeart />
							<p>Saved</p>
						</span>
					)}
				</div>
			)}
			<div className="new__card__signature">
				<div className="top__right">
					<span className="icon__signature">
						{signature !== 0 && <SvgListingSignature />}
					</span>
					<span className="icon__newhome">
						{newHome && <SvgListingNewHomes />}
					</span>
				</div>
				{!newHome && discountPrice && discountPrice - price > 5000 && (
					<div className="icon-rare">
						<SvgRare />
					</div>
				)}
				{newHomeDis && (
					<div className="icon-rare">
						<SvgNewhomesDis />
					</div>
				)}
				<div className="new__card__avail__detail">
					{!hideAvail &&
						(pathname === "/search" ||
							pathname.split("/")[1] === "collection" ||
							pathname.split("/")[1] === "villas" ||
							pathname === "/") && (
							<div className="icon-avail">
								{available === 1 ? (
									<span className="avail">Available</span>
								) : available === 2 ? (
									<span className="avail">Available today</span>
								) : (
									<span className="not-avail">Not available</span>
								)}
							</div>
						)}
					<div className="detail__container">
						<span> {capacity} guests</span>
						<span>
							<p>{noOfBedrooms} Bedroom</p>
						</span>
						<span>
							<p>{type !== "Separate Villas" ? type : "2 Villas"}</p>
						</span>
					</div>
				</div>
			</div>
			<div className="new__card__img__box">
				<Slider {...settings}>
					{img?.map((value: any, i: any) => {
						return (
							<LazyLoadImage
								alt={
									window.location.pathname.includes("villas/")
										? `${parseHTML(title)}, villa in ${
												query.get("location") ||
												window.location.pathname?.split("/")[2]?.split("-")[2]
										  }`
										: parseHTML(title)
								}
								src={value}
								onClick={() => {
									clevertap.event.push("featured_card_website", {
										from: prevLocation,
										listing_name: `${title}`,
										listing_url: `${seoURL + "-"}${id}`,
									});
									if (discountPrice) {
										clevertap.event.push("rare_price_tag", {
											from: prevLocation,
											listing_name: `${title}`,
											listing_url: `${seoURL + "-"}${id}`,
										});
									}
								}}
								key={i}
								effect="blur"
							/>
						);
					})}
				</Slider>
			</div>
		</div>
	);

	const cardBottom = (
		<div className="bottom__container">
			<div className="title__container">
				<span className="title">
					<h2>{parseHTML(title)}</h2>
					<span className="location">
						<p>{location}</p>
						{distance ? <span className="distance">{distance}</span> : null}
					</span>
				</span>
				{rating ? (
					<div
						className="rating"
						style={{
							display: "flex",
						}}
					>
						<SvgStar />
						<span>
							{" "}
							<p>{rating}</p>({reviews})
						</span>
					</div>
				) : null}
			</div>
			<div className="price__container">
				<div className="price">
					<span className="price__per">
						<h4> ₹ {addCommas(price)}</h4>
						<p>/night</p>
						<span
							style={{
								paddingLeft: 6,
								fontSize: 12,
								color: "#555",
							}}
						>
							{type !== "Separate Rooms" &&
								noOfBedrooms > 1 &&
								`(${(price / noOfBedrooms)?.toLocaleString("en-IN", {
									currency: "INR",
									style: "currency",
								})} x ${noOfBedrooms} Rooms)`}
						</span>
					</span>
					{off ? <p>{off}</p> : null}
				</div>
				{discountPrice && price !== discountPrice && (
					<span className="discount__price">
						₹ {addCommas(discountPrice)}
						<hr />
					</span>
				)}
			</div>
		</div>
	);

	return (
		<a
			className="home__new__card__container"
			href={`/view/${seoURL + "-"}${id}?adults=${
				query?.get("guests") || ""
			}&children=${query?.get("children") || ""}&infants=${
				query?.get("infants") || ""
			}&rooms=${query?.get("rooms") || ""}
	&checkin=${query?.get("checkin") ? query.get("checkin") : ""}&checkout=${
				query?.get("checkout") ? query.get("checkout") : ""
			}`}
			target={width >= 800 ? "_blank" : ""}
			rel={width >= 800 ? "noreferrer" : ""}
		>
			{cardTop}
			{cardBottom}
		</a>
	);
};
