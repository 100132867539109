import SvgSignatureList from "../../../../assets/signature.png";
import XSeriesLogo from "../../../../assets/x-series.png";
interface DestinationCategory {
	name: string;
	locations: string[];
}

export const destinations: DestinationCategory[] = [
	{
		name: "Western Ghats",
		locations: [
			"Kamshet",
			"Khandala",
			"Lonavala",
			"Mahabaleshwar",
			"Mulshi",
			"Panchgani",
			"Pawna",
			"Pune",
		],
	},
	{
		name: "Alibaug",
		locations: ["Alibaug", "Dapoli", "Murud", "Kashid"],
	},
	{
		name: "Karjat",
		locations: [
			"Karjat",
			"Khopoli",
			"Pali",
			"Pen",
			"Panvel",
			"Matheran",
			"Murbad",
			"Shahpur",
		],
	},
	{
		name: "Palghar",
		locations: ["Palghar", "Kelwa"],
	},
	{
		name: "Nashik",
		locations: ["Igatpuri", "Nashik"],
	},
	{
		name: "Goa & Konkan",
		locations: ["North Goa", "Redi Beach"],
	},
	{
		name: "Karnataka",
		locations: [
			"Bengaluru",
			"Chikmagalur",
			"Coorg",
			"Hejamady",
			"Mysore",
			"Udupi",
		],
	},
	{
		name: "Tamil Nadu",
		locations: ["Kodaikanal", "Ooty"],
	},
	{
		name: "Kerala",
		locations: ["Kannur"],
	},
	{
		name: "Uttarakhand",
		locations: [
			"Bhimtal",
			"Dehradun",
			"Mukteshwar",
			"Mussoorie",
			"Nainital",
			"Ranikhet",
		],
	},
	{
		name: "Himachal",
		locations: [
			"Bir Billing",
			"Chamba",
			"Kasauli",
			"Manali",
			"Shimla",
			"Tirthan",
		],
	},
	{
		name: "Rajasthan",
		locations: ["Udaipur", "Jaipur"],
	},
	{
		name: "Delhi NCR",
		locations: ["Gurugram"],
	},
	{
		name: "Punjab",
		locations: ["Hoshiarpur"],
	},
];

interface CollectionsTypes {
	name: string;
	items: string[];
	slugs: string[];
}

export const collections: CollectionsTypes[] = [
	{
		name: "Celebrating",
		items: [
			// "Anniversary",
			// "Bachelorette",
			"Corporate Offsite",
			"Get-together",
			"Birthday",
			"Wedding",
			//"Bachelorette",
		],
		slugs: [
			// "",
			// "",
			"collection/corporate-getaway",
			"collection/book-large-villas-big-group-bungalow-rent",
			"collection/top-villas-for-house-parties",
			"collection/fairytale-wedding-villas",
			//"best-villas-for-bachelorette-party"
		],
	},
	{
		name: "People",
		items: [
			"Child-friendly",
			"Couples-friendly",
			"Elderly-friendly",
			"Pet-friendly",
		],
		slugs: [
			"collection/children-friendly-holiday-villas",
			"collection/top-couple-friendly-villas-for-romantic-holiday",
			"villas/villas-near-me?checkin=&checkout=&guests=1&adults=0&children=0&infants=0&pets=0&rooms=1&lat=19.0748&lon=72.8856&amenities=&events=&pocket=&same_day=&club=&signature=&breakfast=&radius=1&more=31",
			"collection/pet-friendly-villas",
		],
	},
	{
		name: "Feeling",
		items: [
			"Beaches",
			"Hillside",
			"Lakeview",
			//"Private Pool",
			"Stunning Vistas",
			// "Woods",
		],
		slugs: [
			"collection/best-villas-by-the-beach-for-your-next-famjam",
			"collection/family-getaway-to-the-hills",
			"collection/best-lake-view-villa",
			//"top-private-pool-villas",
			"collection/best-impeccable-view-villas",
			// "woods",
		],
	},
	{
		name: "Nearby",
		items: [
			"3 hours from Mumbai",
			"2 hours from Pune",
			"6 hours from Delhi",
			"6 hours from Bengaluru",
		],
		slugs: [
			"collection/villas-near-mumbai",
			"collection/villas-near-pune",
			"collection/villas-near-delhi",
			"collection/getaway-villas-from-bangalore",
		],
	},
];

interface Amenity {
	title: string;
	link: string;
}

export const amenities: Amenity[] = [
	{
		title: "Pool Villas",
		link: "/villas/villas-near-me?amenities=28&events=&more=",
	},
	{
		title: "Homes with Jacuzzi",
		link: "/villas/villas-near-me?amenities=75&events=&more=",
	},
	// {
	//   title: "Heated Pool",
	//   link: "/villas/villas-near-me?amenities=&events=&more=",
	// },
	// {
	//   title: "Homes with Local Food",
	//   link: "/villas/villas-near-me?amenities=&events=&more=",
	// },
	// {
	//   title: "Homes that allow Kitchen Access",
	//   link: "/villas/villas-near-me?amenities=&events=&more=",
	// },
	// {
	//   title: "Homes that allow Order-in",
	//   link: "/villas/villas-near-me?amenities=&events=&more=",
	// },
	{
		title: "Veg-Only Homes",
		link: "/villas/villas-near-me?amenities=16&events=&more=",
	},
	// {
	//   title: "Homes with Games Area",
	//   link: "/villas/villas-near-me?amenities=&events=&more=",
	// },
	{
		title: "Homes with Lawns",
		link: "/villas/villas-near-me?amenities=&events=&more=21%2C15&radius=1",
	},
	// {
	//   title: "Homes with Verandahs",
	//   link: "/villas/villas-near-me?amenities=&events=&more=",
	// },
	{
		title: "High-speed WiFi",
		link: "/villas/villas-near-me?amenities=&events=&more=68",
	},
	// {
	//   title: "Homes with Home Theaters/Projectors",
	//   link: "/villas/villas-near-me?amenities=&events=&more=",
	// },
	{
		title: "Waterfront",
		link: "/villas/villas-near-me?amenities=&events=&more=14",
	},
	{
		title: "Breakfast Included",
		link: "/villas/villas-near-me?amenities=&events=&more=&breakfast=true",
	},
];

type InspiringIdeas = {
	title: string;
	slug: string;
};

export const inspiringIdeas: InspiringIdeas[] = [
	{ title: "Corporate Offsites", slug: "/collection/corporate-getaway" },
	{ title: "Weddings", slug: "/collection/fairytale-wedding-villas" },
	{
		title: "Milestone Birthdays",
		slug: "/collection/top-villas-for-house-parties",
	},
	{ title: "Anniversaries", slug: "/collection/celebrations-with-plus-one" },
	{ title: "Family Get-togethers", slug: "/collection/family-staycation" },
	// { title: "Girl's Getaways", slug: "" },
	{
		title: "Celebrity Choice Villas",
		slug: "/collection/celebrity-choice-perfect-for-instagram-villa",
	},
];

type Categories = {
	title: string;
	description: string;
	icon: string;
	link: string;
};

export const categories: Categories[] = [
	{
		title: "X Series",
		description: "Larger than Life Estates",
		icon: "https://ik.imagekit.io/5tgxhsqev/saffronstays-media/xseries.svg",
		link: "/villas/villas-near-me?checkin=&checkout=&guests=1&adults=0&children=0&infants=0&pets=0&rooms=1&amenities=&events=&pocket=&same_day=&club=&signature=&breakfast=&radius=1&more=31&x-series=true",
	},
	{
		title: "Signature Homes",
		description: "Top of the Line Luxury Villas",
		icon: "https://ik.imagekit.io/5tgxhsqev/saffronstays-media/signature.svg",
		link: "/villas/villas-near-me?checkin=&checkout=&guests=1&adults=0&children=0&infants=0&pets=0&rooms=1&amenities=&events=&pocket=&same_day=&club=&signature=true&breakfast=&radius=1&more=31&x-series=false",
	},
	{
		title: "Marriott Bonvoy",
		description: "In partnership with Homes & Villas by Marriott",
		icon: "https://ik.imagekit.io/5tgxhsqev/saffronstays-media/marriottBonvoy.svg",
		link: "/villas/villas-near-me?checkin=&checkout=&guests=1&adults=0&children=0&infants=0&pets=0&rooms=1&amenities=&events=&pocket=&same_day=&club=&signature=false&breakfast=&radius=1&more=31&x-series=false&next=true",
	},
	{
		title: "Club Infinitum",
		description: "Redeem your reward points",
		icon: "https://ik.imagekit.io/5tgxhsqev/saffronstays-media/clubInfinitum.svg",
		link: "/villas/villas-near-me?checkin=&checkout=&guests=1&adults=0&children=0&infants=0&pets=0&rooms=1&amenities=&events=&pocket=&same_day=&club=true&signature=false&breakfast=&radius=1&more=31&x-series=false&next=false",
	},
];
