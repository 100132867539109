import Footer from "components/Footer";
import "./style.scss";
import { ChangeEvent, SetStateAction, useState } from "react";
import FeaturedScrollMenu from "components/Home/HomeFeatured/FeaturedScrollMenu";
import { FeaturedHomesData } from "context/FeaturedHomesData";
import { ReactComponent as SvgLogo } from "./assets/logo.svg";
import Modal from "components/LoginAndRegister/Modal";
import { ReactComponent as BlockLogo } from "./assets/block-logo.svg";
import { customDates, format } from "multi-date";
import { ReactComponent as BookingPassLogo } from "./assets/booking-pass.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { RxCross2 } from "react-icons/rx";
import { IoTicketOutline } from "react-icons/io5";
import { axiosInstance } from "helpers/axiosInstance";

const MarketingPage = () => {
	const [open, setOpen] = useState(false);
	return (
		<>
			<Modal
				shouldShow={open}
				onRequestClose={() => {
					setOpen(false);
				}}
			>
				<LeadForm setOpen={setOpen} />
			</Modal>
			<main className="marketing-feature-wrapper">
				<section className="hero-wrapper">
					<SvgLogo id="logo" />
					<h1>
						Exclusive Goa Stays for
						<br />
						GOX Members
					</h1>
					<p>
						Unlock luxury villas with special perks and enjoy an unforgettable
						stay in Goa.
					</p>
					<button onClick={() => setOpen(true)}>Book your villa</button>
				</section>
				<FeaturedHomesData>
					<FeaturedScrollMenu />
				</FeaturedHomesData>
				<Footer />
			</main>
		</>
	);
};

export default MarketingPage;

type TStep = "form" | "upload";

const LeadForm = ({
	setOpen,
}: {
	setOpen: React.Dispatch<SetStateAction<boolean>>;
}) => {
	const [step, setStep] = useState<TStep>("form");
	const [data, setData] = useState({
		full_name: "",
		phone: "",
		checkin: format(customDates(new Date(), 1, "day"), "YYYY-MM-DD"),
		checkout: format(customDates(new Date(), 5, "day"), "YYYY-MM-DD"),
		file: "",
	});
	const [uploading, setUploading] = useState(false);
	const [progress, setProgress] = useState(0);

	const getFileUrl = async (props: ChangeEvent<HTMLInputElement>) => {
		if (props.target.files && props.target.files?.length > 0) {
			setUploading(true);
			setProgress(0);
			try {
				let data = new FormData();
				data.append("image", props.target.files[0]);
				data.append("bucketName", "saffronstays-media/gox");
				const res = await axios.post(
					"https://ecapi.saffronstays.com/s3/upload",
					data,
					{
						onUploadProgress: (progressEvent: any) => {
							const percentCompleted = Math.round(
								(progressEvent.loaded * 100) / progressEvent.total,
							);
							setProgress(percentCompleted);
						},
					},
				);
				if (res.status === 200) {
					setData((prev) => {
						return {
							...prev,
							file: res.data.data.public_id,
						};
					});
					return;
				}
			} catch (err) {
				toast.error("Error uploading file!");
				throw err;
			} finally {
				setUploading(false);
			}
		}
	};

	const onSubmit = async () => {
		try {
			const res = await axios.post(
				"https://go.saffronstays.com/api/add-lead-goa",
				data,
			);
			if (res.data.success) {
				toast.success(res.data.message);
			}
		} catch (err) {
			toast.error("Error! Try Again.");
		} finally {
			setOpen(false);
		}
	};

	return (
		<div className="lead-feature-form-wrapper">
			<div className="form-wrapper">
				<BlockLogo />
				<h2>Welcome to SaffronStays</h2>
				<p>Book your villa</p>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						if (step === "upload") {
							onSubmit();
							return;
						}
						axiosInstance({
							method: "post",
							url: "/create-lead",
							headers: {
								"Content-Type": "application/json",
							},
							data: {
								GUEST_NAME: data.full_name,
								GUEST_NUMBER: data.phone,
								CHECK_IN: format(data.checkin, "YYYY-MM-DD"),
								CHECK_OUT: format(data.checkout, "YYYY-MM-DD"),
								MEDIUM: "Website - Gox Feature",
							},
						});
						setStep("upload");
					}}
				>
					{step === "form" && (
						<>
							<FeatureInputWrapper
								placeholder="Enter Your Name"
								title="Full Name"
								type="text"
								value={data.full_name}
								required
								onChange={(e) => {
									setData((prev) => {
										return {
											...prev,
											full_name: e.target.value,
										};
									});
								}}
							/>
							<FeatureInputWrapper
								placeholder="Enter Your Mobile Number"
								title="Phone Number"
								type="tel"
								value={data.phone}
								required
								onChange={(e) => {
									setData((prev) => {
										return {
											...prev,
											phone: e.target.value,
										};
									});
								}}
							/>
							<div className="form-input-wrapper-row">
								<div className="form-input-wrapper">
									<span>Checkin</span>
									<input
										type="date"
										value={data.checkin}
										onChange={(e: ChangeEvent<HTMLInputElement>) => {
											setData((prev) => {
												return { ...prev, checkin: e.target.value };
											});
										}}
										required
									/>
								</div>
								<div className="form-input-wrapper">
									<span>Checkout</span>
									<input
										type="date"
										value={data.checkout}
										onChange={(e: ChangeEvent<HTMLInputElement>) => {
											setData((prev) => {
												return { ...prev, checkout: e.target.value };
											});
										}}
										required
									/>
								</div>
							</div>
						</>
					)}
					{step === "upload" && !data.file && (
						<>
							{!uploading ? (
								<label htmlFor="file-upload">
									<div className="boarding-pass-wrapper">
										<BookingPassLogo />
										<input
											style={{ display: "none" }}
											type="file"
											name="file-upload"
											id="file-upload"
											accept=".jpg, .jpeg, .png, .pdf"
											onChange={getFileUrl}
										/>
										<span>
											Upload your <b>Boarding Pass</b>
										</span>
										<div className="input-title">
											Drop your image or browse image
										</div>
										<div className="input-sub-title">
											Supports JPG, JPEG, PNG, PDF
										</div>
									</div>
								</label>
							) : (
								<>
									<div className="progress-bar-uploading">
										<div
											className="progress-bar"
											style={{
												width: progress + "%",
											}}
										></div>
									</div>
									<span>Uploading.. ({progress + "%"})</span>
								</>
							)}

							<p>
								Upload your boarding pass to verify flight and enjoy exclusive
								benefits at SaffronStays Villas.
							</p>
						</>
					)}
					{data.file && (
						<div className="boarding-pass-confirm">
							<a
								className="boarding-pass-preview"
								href={
									"https://ik.imagekit.io/5tgxhsqev/saffronstays-media/" +
									data.file
								}
								target="_blank"
								rel="noopener noreferrer"
							>
								{!data.file.includes(".pdf") ? (
									<img
										src={
											"https://ik.imagekit.io/5tgxhsqev/saffronstays-media/tr:f-webp,q-10/" +
											data.file
										}
										alt=""
									/>
								) : (
									<IoTicketOutline />
								)}
								<div className="boarding-pass-container">
									<span>Preview</span>
								</div>
							</a>
							<RxCross2
								onClick={(e) => {
									e.stopPropagation();
									setData({ ...data, file: "" });
								}}
							/>
						</div>
					)}
					<button disabled={step === "upload" && !data.file}>
						{step === "form" ? "Next" : "Request a callback"}
					</button>
				</form>
			</div>
			<img
				src="https://ik.imagekit.io/5tgxhsqev/saffronstays-media/tr:q-25,f-webp/image/upload/p7a5mg5bumhnr1epbxhu"
				alt=""
			/>
		</div>
	);
};
interface InputProps {
	type: React.HTMLInputTypeAttribute;
	value: string;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	title: string;
	placeholder?: string;
	required: boolean;
}

const FeatureInputWrapper = ({
	type,
	value,
	onChange,
	title,
	placeholder,
	required,
}: InputProps) => {
	return (
		<div className="form-input-wrapper">
			<span>{title}</span>
			<input
				placeholder={placeholder}
				type={type}
				value={value}
				onChange={onChange}
				required={required}
			/>
		</div>
	);
};
