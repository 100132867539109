import { axiosInstance } from "./axiosInstance";

export const AddingToCollection = async (listingId: any, value: boolean) => {
	try {
		await axiosInstance({
			method: "POST",
			url: "/shortlist",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			data: {
				listing_id: listingId,
				shortlist: value === false ? "1" : "0",
			},
		});
		// ListingData.refetch();
	} catch (error: any) {
		// @ts-ignore
		throw new Error({ cause: error });
	}
};
