interface Destination {
	region: string;
	places: string[];
}

export const destinations: Destination[] = [
	{
		region: "Maharashtra",
		places: [
			"Alibaug",
			"Dapoli",
			"Murud",
			"Kashid",
			"Kamshet",
			"Khandala",
			"Lonavala",
			"Mahabaleshwar",
			"Mulshi",
			"Panchgani",
			"Pawna",
			"Pune",
			"Karjat",
			"Khopoli",
			"Pali",
			"Pen",
			"Panvel",
			"Matheran",
			"Murbad",
			"Shahapur",
			"Palghar",
			"Kelwa",
			"Igatpuri",
			"Nashik",
		],
	},
	{
		region: "North India",
		places: [
			"Uttarakhand",
			"Bhimtal",
			"Dehradun",
			"Mukteshwar",
			"Mussoorie",
			"Nainital",
			"Ranikhet",
			"Himachal",
			"Bir Billing",
			"Chamba",
			"Kasauli",
			"Manali",
			"Shimla",
			"Tirthan",
			"Punjab",
			"Hoshiarpur",
			"Rajasthan",
			"Udaipur",
			"Jaipur",
			"Delhi NCR",
			"Gurugram",
		],
	},
	{
		region: "South India",
		places: [
			"Goa & Konkan",
			"North Goa",
			"Redi Beach",
			"Kerala",
			"Kannur",
			"Karnataka",
			"Bengaluru",
			"Chikkamagaluru",
			"Coorg",
			"Hejamadi",
			"Mysore",
			"Udupi",
			"Tamil Nadu",
			"Kodaikanal",
			"Ooty",
		],
	},
];

interface Collection {
	category: string;
	items: string[];
	icon: string;
	slugs: string[];
}

export const collections: Collection[] = [
	{
		category: "Celebrating",
		icon: "https://ik.imagekit.io/5tgxhsqev/saffronstays-media/celebrating.svg",
		items: ["Corporate Offsite", "Get-together", "Birthday", "Wedding"],
		slugs: [
			"collection/corporate-getaway",
			"collection/book-large-villas-big-group-bungalow-rent",
			"collection/top-villas-for-house-parties",
			"collection/fairytale-wedding-villas",
		],
	},
	{
		category: "People",
		icon: "https://ik.imagekit.io/5tgxhsqev/saffronstays-media/people.svg",
		items: [
			"Child-friendly",
			"Couples-friendly",
			"Elderly-friendly",
			"Pet-friendly",
		],
		slugs: [
			"collection/children-friendly-holiday-villas",
			"collection/top-couple-friendly-villas-for-romantic-holiday",
			"villas/villas-near-me?checkin=&checkout=&guests=1&adults=0&children=0&infants=0&pets=0&rooms=1&lat=19.0748&lon=72.8856&amenities=&events=&pocket=&same_day=&club=&signature=&breakfast=&radius=1&more=31",
			"collection/pet-friendly-villas",
		],
	},
	{
		category: "Feeling",
		icon: "https://ik.imagekit.io/5tgxhsqev/saffronstays-media/feeling.svg",
		items: ["Beaches", "Hillside", "Lakeview","Stunning Vistas",],
		slugs: [
			"collection/best-villas-by-the-beach-for-your-next-famjam",
			"collection/family-getaway-to-the-hills",
			"collection/best-lake-view-villa",
			"collection/best-impeccable-view-villas",
		],
	},
	{
		category: "Nearby",
		icon: "https://ik.imagekit.io/5tgxhsqev/saffronstays-media/nearby.svg",
		items: [
			"3 hours from Mumbai",
			"2 hours from Pune",
			"6 hours from Delhi",
			"6 hours from Bengaluru",
		],
		slugs: [
			"collection/villas-near-mumbai",
			"collection/villas-near-pune",
			"collection/villas-near-delhi",
			"collection/getaway-villas-from-bangalore",
		],
	},
];

interface Amenities {
	title: string;
	link: string;
}

export const amenities: Amenities[] = [
	{
		title: "Homes with Private Pool",
		link: "/villas/villas-near-me?amenities=28&events=&more=",
	},
	{
		title: "Homes with Jacuzzi",
		link: "/villas/villas-near-me?amenities=75&events=&more=",
	},
	{
		title: "Veg-Only Homes",
		link: "/villas/villas-near-me?amenities=16&events=&more=",
	},

	{
		title: "Homes with Lawns",
		link: "/villas/villas-near-me?amenities=&events=&more=21%2C15&radius=1",
	},

	{
		title: "High-speed WiFi",
		link: "/villas/villas-near-me?amenities=&events=&more=68",
	},
	{
		title: "Waterfront Homes",
		link: "/villas/villas-near-me?amenities=&events=&more=14",
	},
	{
		title: "Breakfast Included",
		link: "/villas/villas-near-me?amenities=&events=&more=&breakfast=true",
	},
];


type Categories = {
	title: string;
	description: string;
	icon: string;
	link: string;
};

export const categories: Categories[] = [
	{
		title: "X Series",
		description: "Larger than Life Estates",
		icon: "https://ik.imagekit.io/5tgxhsqev/saffronstays-media/xseries.svg",
		link: "/villas/villas-near-me?checkin=&checkout=&guests=1&adults=0&children=0&infants=0&pets=0&rooms=1&amenities=&events=&pocket=&same_day=&club=&signature=&breakfast=&radius=1&more=31&x-series=true",
	},
	{
		title: "Signature Homes",
		description: "Top of the Line Luxury Villas",
		icon: "https://ik.imagekit.io/5tgxhsqev/saffronstays-media/signature.svg",
		link: "/villas/villas-near-me?checkin=&checkout=&guests=1&adults=0&children=0&infants=0&pets=0&rooms=1&amenities=&events=&pocket=&same_day=&club=&signature=true&breakfast=&radius=1&more=31&x-series=false",
	},
	{
		title: "Marriott Bonvoy",
		description: "In partnership with Homes & Villas by Marriott",
		icon: "https://ik.imagekit.io/5tgxhsqev/saffronstays-media/marriottBonvoy.svg",
		link: "/villas/villas-near-me?checkin=&checkout=&guests=1&adults=0&children=0&infants=0&pets=0&rooms=1&amenities=&events=&pocket=&same_day=&club=&signature=false&breakfast=&radius=1&more=31&x-series=false&next=true",
	},
	{
		title: "Club Infinitum",
		description: "Redeem your reward points",
		icon: "https://ik.imagekit.io/5tgxhsqev/saffronstays-media/clubInfinitum.svg",
		link: "/villas/villas-near-me?checkin=&checkout=&guests=1&adults=0&children=0&infants=0&pets=0&rooms=1&amenities=&events=&pocket=&same_day=&club=true&signature=false&breakfast=&radius=1&more=31&x-series=false&next=false",
	},
];;
